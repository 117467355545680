const Spacer = ({
	axis,
	type,
	value,
}: {
	axis: "horizontal" | "vertical"
	type?:
		| "betweenChips"
		| "screenMobile"
		| "betweenComponents"
		| "betweenTexts"
		| "imageBottom"
		| "textToComponent"
		| "contentToButton"
		| "betweenSections"
		| "betweenOptions"
		| "belowSectionHeader"
		| "betweenButtons"
		| "betweenFields"
	value?: string
}) => {
	const crossTypes = {
		horizontal: {
			betweenChips: "ml-betweenChips",
			screenMobile: "ml-screenMobile",
			betweenComponents: "ml-betweenComponents",
			betweenTexts: "ml-betweenTexts",
			imageBottom: "ml-imageBottom",
			textToComponent: "ml-textToComponent",
			contentToButton: "ml-contentToButton",
			betweenSections: "ml-betweenSections",
			betweenOptions: "ml-betweenOptions",
			belowSectionHeader: "ml-belowSectionHeader",
			betweenButtons: "ml-betweenButtons",
			betweenFields: "ml-betweenFields",
		},
		vertical: {
			betweenChips: "mt-betweenChips",
			screenMobile: "mt-screenMobile",
			betweenComponents: "mt-betweenComponents",
			betweenTexts: "mt-betweenTexts",
			imageBottom: "mt-imageBottom",
			textToComponent: "mt-textToComponent",
			contentToButton: "mt-contentToButton",
			betweenSections: "mt-betweenSections",
			betweenOptions: "mt-betweenOptions",
			belowSectionHeader: "mt-belowSectionHeader",
			betweenButtons: "mt-betweenButtons",
			betweenFields: "mt-betweenFields",
		},
	}
	if (axis === "horizontal") {
		return (
			<div
				className={`h-1 w-1 ${type ? crossTypes[axis][type] : value ?? ""}`}
			></div>
		)
	} else {
		return (
			<div
				className={`w-1 h-1 ${type ? crossTypes[axis][type] : value ?? ""}`}
			></div>
		)
	}
}

export default Spacer
