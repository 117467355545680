import { getAddress } from "ethers"
import { AlertCircle, SaveIcon } from "lucide-react"
import { useState } from "react"
import { networks } from "../../utils/networks"
import { Alert } from "../ui/alert"
import { Button } from "../ui/button"
import LoadableButton from "../ui/buttons/LoadableButton"
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "../ui/dialog"
import { Input } from "../ui/inputs/input"
import Row from "../ui/layouts/row"
import { useToast } from "../ui/use-toast"
import { WithdrawType } from "../../utils/withdrawCurrencies"

export interface NewAddressStepProps {
	onConfirmAddress: () => void
	address?: string
	network?: string
	onAddressChange: (address: string) => void
	onNewAddress: ({ address, alias }: { address: string; alias: string }) => void
	validateAddress: (address: string) => boolean
	placeholder: string
	withdrawType: WithdrawType
}

const NewAddressStep = ({
	onConfirmAddress,
	address,
	validateAddress,
	network,
	onAddressChange,
	onNewAddress,
	placeholder,
	withdrawType,
}: NewAddressStepProps) => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState(false)
	const [alias, setAlias] = useState<string | undefined>()
	const { toast } = useToast()

	const onContinue = () => {
		console.log(address)
		if (address && validateAddress(address)) {
			onConfirmAddress()
		} else {
			setIsLoading(true)
			setTimeout(() => {
				setError(true)
				setIsLoading(false)
				toast({
					variant: "destructive",
					title: "Error",
					description: "La dirección ingresada es inválida",
				})
			}, 1000)
		}
	}

	return (
		<>
			{withdrawType === "crypto" && (
				<Alert
					variant="warning"
					icon={<AlertCircle className="size-[20px]" />}
					title="Cuidado"
					description={`Deberán ser billeteras que pertenezcan a la red seleccionada: ${
						network && networks[network] ? networks[network].name : ""
					}`}
				/>
			)}

			<Row>
				<Input
					id="address"
					placeholder={placeholder}
					onChange={(e) => {
						setError(false)
						onAddressChange(e.target.value)
					}}
					data-test='withdraw-address-input'
				/>
				<div className="justify-end  h-fit flex flex-row items-center">
					{address && (
						<Dialog>
							<DialogTrigger asChild>
								<Button
									variant="secondary"
									size="flex"
									className="ml-betweenCards size-lg px-0"
								>
									<SaveIcon className="size-sm" />
								</Button>
							</DialogTrigger>

							<DialogContent className="sm:max-w-[425px]">
								<DialogHeader>
									<DialogTitle>Nueva dirección</DialogTitle>
									<DialogDescription>
										Asignale un nombre a tu dirección para poder identificarla
										más fácil.
									</DialogDescription>
								</DialogHeader>
								<div className="grid gap-4 py-4">
									<Input
										id="alias"
										placeholder="Alias"
										onChange={(e) => {
											setAlias(e.target.value)
										}}
									/>
								</div>
								<DialogFooter>
									<DialogClose>
										<LoadableButton
											size="md"
											onClick={async () => {
												setIsLoading(true)
												setError(false)

												if (alias && validateAddress(address)) {
													await onNewAddress({
														address: address,
														alias: alias,
													})
													setTimeout(() => {
														toast({
															title: "Dirección guardada",
															description:
																"Ya se guardó la direccion en tu lista de envíos",
															// action: (
															// 	<ToastAction altText="Undo">
															// 		Deshacer
															// 	</ToastAction>
															// ),
														})
													}, 1000)
												} else {
													setError(true)
													toast({
														variant: "destructive",
														title: "Error",
														description: "La dirección ingresada es inválida",
													})
												}
												setIsLoading(false)
											}}
											isLoading={isLoading}
										>
											Guardar dirección
										</LoadableButton>
									</DialogClose>
								</DialogFooter>
							</DialogContent>
						</Dialog>
					)}
				</div>
			</Row>

			<LoadableButton
				size="full"
				onClick={onContinue}
				isLoading={isLoading}
				disabled={error}
				data-test='withdraw-address-continue-button'
			>
				Confirmar
			</LoadableButton>
		</>
	)
}

export default NewAddressStep
