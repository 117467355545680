export enum OnboardingPersonalSteps {
	SpecialAgreements,
	BasicInfo,
	Success,
}

export type PersonalDocumentType = "CUIT"

export type EntityType = "F"

export type SpecialAgreementsForm = {
	obligated_subject: boolean
	exposed_person: boolean
	fatca_subject: boolean
	ocde_subject: boolean
}

export type BasicInfoForm = {
	first_name: string
	last_name: string
	document_type: PersonalDocumentType
	cuit: string
} & ContactInfoForm

export type ContactInfoForm = {
	phone: string
	address: string
}

export type DniUploadForm = {
	dni_front_file: File | null
	dni_back_file: File | null
}

export type FacialVerificationForm = {
	facial_video_file: File | null
}

export type OnboardingPersonalData = BasicInfoForm & {
	uif_conditions: SpecialAgreementsForm
} & {
	entity_type: string
}

export type ImageStatus = "PENDING" | "UPLOADED"

export type KycGetResponse = {
	basic: BasicInfoForm & { entity_type: EntityType }
	contact: ContactInfoForm
	kyc_status: "pending"
	uif_conditions: SpecialAgreementsForm
}

export interface OnboardingPersonalWizardData {
	step: OnboardingPersonalSteps
	completionPercentage: number
	data: OnboardingPersonalData
	loading?: boolean
	errorMessage?: string
}
