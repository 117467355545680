import { Heading5, Heading6 } from "../../../ui/texts/TextStyle"
import CopyButton from "../../../ui/buttons/CopyButton"
import { ReactNode } from "react"

const BodyItem = ({
	name,
	value,
	copy = false,
	overflow = false,
}: {
	name: string
	value: ReactNode | string
	copy?: boolean
	overflow?: boolean
}) => {
	return (
		<div className="grid grid-cols-2 py-betweenTexts items-center">
			<Heading6 className="font-medium text-text-muted">{name}</Heading6>
			<div className="flex justify-end mb-2 whitespace-nowrap items-center">
				<Heading5
					className={`font-medium ${
						overflow ? "overflow-ellipsis overflow-hidden" : ""
					}`}
				>
					{value}
				</Heading5>
				{copy && typeof value === "string" && (
					<CopyButton
						size={20}
						buttonSize="icon"
						variant="ghost"
						textToCopy={value}
						className="hover:bg-transparent cursor-pointer hover:text-secondary"
					/>
				)}
			</div>
		</div>
	)
}

export default BodyItem
