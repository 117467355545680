import { Table } from "@tanstack/react-table"
import { X } from "lucide-react"
import { Button } from "../button"
import { Input } from "../inputs/input"
import {
	DataTableFacetedFilter,
	DataTableFacetedFilterProps,
} from "./data-table-faceted-filter"
import { DataTableViewOptions } from "./data-table-view-options"

interface DataTableToolbarProps<TData, TValue> {
	table: Table<TData>
	searchInput?: {
		placeholder: string
		columnName: string
	}
	badgeFilters?: DataTableFacetedFilterProps<TData, TValue>[]
	viewOptions?: boolean
}

export function DataTableToolbar<TData, TValue>({
	table,
	searchInput,
	badgeFilters,
	viewOptions = false,
}: DataTableToolbarProps<TData, TValue>) {
	const isFiltered = table.getState().columnFilters.length > 0
	return (
		<div className="flex items-center justify-between">
			<div className="flex flex-1 items-center space-x-2 py-betweenFields">
				{searchInput &&
					table
						.getAllLeafColumns()
						.find((col) => col.id === searchInput.columnName)
						?.getIsVisible() && (
						<div className="w-[150px] lg:w-[250px]">
							<Input
								placeholder={searchInput.placeholder}
								value={
									(table
										.getColumn(searchInput.columnName)
										?.getFilterValue() as string) ?? ""
								}
								onChange={(event) =>
									table
										.getColumn(searchInput.columnName)
										?.setFilterValue(event.target.value)
								}
							/>
						</div>
					)}
				{badgeFilters &&
					badgeFilters
						.filter(
							(filter) =>
								filter.column &&
								table
									.getAllLeafColumns()
									.find((col) => col.id === filter.column?.id)
									?.getIsVisible()
						)
						.map((filter) => (
							<DataTableFacetedFilter key={filter.title} {...filter} />
						))}
				{isFiltered && (
					<Button
						variant="ghost"
						size="md"
						onClick={() => table.resetColumnFilters()}
						className="h-8 px-2 lg:px-3"
					>
						Reset
						<X className="ml-2 h-4 w-4" />
					</Button>
				)}
			</div>
			{viewOptions && <DataTableViewOptions table={table} />}
		</div>
	)
}

export default DataTableToolbar
