import { formatEther } from "ethers"
import { BalanceItem } from "../../utils/balances"
import { currencies } from "../../utils/currencies"
import CenteredBody from "../ui/layouts/platform/body/CenteredBody"
import CurrencyOption from "../ui/options/CurrencyOption"
import { Section } from "../ui/section"
import { SectionHeader } from "../ui/section-header"

const SelectCurrencyStep = ({
	balances,
	onCurrencySelected,
}: {
	balances: BalanceItem[]
	onCurrencySelected: (currency: string, network: string) => void
}) => {
	console.log(balances)
	// const noBalance = balances.every((balance) => balance.amountDesired === 0)
	return (
		<CenteredBody>
			<SectionHeader
				title="Retirar criptomonedas"
				subtitle="Seleccioná qué moneda querés retirar"
			/>
			<Section footer="Solo se mostrarán las monedas que tengas disponibles en tu billetera.">
				{balances
					.filter((bal) => currencies[bal.currency])
					.map((balance: BalanceItem) => (
						<CurrencyOption
							key={balance.currency}
							amountDesired={parseFloat(
								balance.amount_preferred_currency_readable
							)}
							amount={formatEther(balance.amount)}
							currency={balance.currency}
							onClick={() =>
								onCurrencySelected(balance.currency, balance.network)
							}
							name={currencies[balance.currency].name}
							logoPath={`/currencies/${balance.currency.toLowerCase()}-icon.svg`}
							pix={balance.network === "POLYGON" ? true : false}
						/>
					))}
			</Section>
		</CenteredBody>
	)
}

export default SelectCurrencyStep
