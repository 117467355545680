import { ColumnDef, Row } from "@tanstack/react-table"
import TRow from "../../../ui/layouts/row"
import { Heading4 } from "../../../ui/texts/TextStyle"
import { Price, TableTransaction } from "../columns"

export function CreditedMoneyColumn(): ColumnDef<TableTransaction> {
	return {
		accessorKey: "received",
		header: () => <div className="text-end ">Acreditado</div>,
		cell: ({ row }: { row: Row<TableTransaction> }) => {
			const received = row.getValue("received") as Price
			const dateTime = row.original.lastModifiedDateTime as {
				date: string
				time: string
			}
			if (received) {
				return (
					<div className={"grid flex-col gap-1 justify-end w-full "}>
						<TRow className=" gap-1 justify-end">
							<Heading4 className="font-bold text-end">
								{received?.amount}
							</Heading4>
							<Heading4 className="font-light text-end">
								{received?.currency}
							</Heading4>
						</TRow>

						<span className="text-b1 text-text-muted  text-end">
							{/* Display the timestamp */}
							{`${dateTime.date} ${dateTime.time}`}
						</span>
					</div>
				)
			} else {
				return (
					<div className="h-4 w-full rounded-xs bg-background-elevated"></div>
				)
			}
		},
	}
}
