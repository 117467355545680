import { Price } from "../../components/tables/transactions-table/columns"
import { WithdrawType } from "../../utils/withdrawCurrencies"

export enum WithdrawSteps {
	SelectType,
	SelectCurrency,
	SelectNetwork,
	SelectAddress,
	EnterAmount,
	Loading,
	Success,
}

export interface FiatAddressDetails {
	fullName: string
	bank_number: string
	cbu: string
}


export interface WithdrawData {
	withdrawType? : WithdrawType
	currency?: string
	network?: string
	address?: string
	fiatAddressDetails?: FiatAddressDetails
	amount?: Price
	quote?: any //TODO CAMBIAR EL TIPO
	transaction_id?: string
}

export interface WithdrawWizardData {
	step: WithdrawSteps
	data: WithdrawData
	loading?: boolean
	errorMessage?: string
}

export { }

