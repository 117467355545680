import { LogOut, Menu, QrCode, Users } from "lucide-react"
import { useState } from "react"
import { Link } from "react-router-dom"
import { useAuth } from "../../../../context/AuthContext"
import { PublicRole, getMaxRole } from "../../../../lib/roles"
import { Button } from "../../button"
import LabelButton from "../../buttons/LabelButton"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "../../dropdown-menu"
import { statusClasses } from "./BottomBarMenuItem"

const BottomBarDropdown = () => {
	const [open, setOpen] = useState(false)
	const { user } = useAuth()
	const labelButtonClass =
		"border-2 border-neutral-700 bg-transparent text-text-navbar"
	const isPos = getMaxRole(user.roles ?? user.talo_role_list) === PublicRole.POS
	return (
		<DropdownMenu onOpenChange={(open) => setOpen(open)}>
			<DropdownMenuTrigger asChild>
				<div
					className={
						"w-[56px] h-[56px] p-xs flex items-center justify-center " +
						statusClasses[open ? "active" : "inactive"]
					}
				>
					<Menu />
				</div>
			</DropdownMenuTrigger>
			<DropdownMenuContent
				sideOffset={24}
				side="top"
				className="max-h-[392px] p-betweenComponents w-[calc(var(--radix-popper-available-width)-2*theme(spacing.screenMobile))] mx-screenMobile rounded-md border-border-navbar bg-background-navbar border-8"
			>
				<DropdownMenuItem>
					<Button
						size="full"
						className="bg-neutral-700 text-negative-200 h-[62px]"
					>
						Cerrar sesión <LogOut className="ml-betweenCards" size={20} />
					</Button>
				</DropdownMenuItem>
				<DropdownMenuSeparator className="bg-neutral-700 mx-screenMobile my-betweenSections" />
				<DropdownMenuGroup className="flex flex-row gap-betweenComponents pb-betweenComponents">
					{!isPos && (
						<Button className="basis-1/2 bg-stone-800 text-amber-300" asChild>
							<Link to="/settings/plugins">Integraciones</Link>
						</Button>
					)}
					<Button className="basis-1/2 bg-neutral-800 text-emerald-200" asChild>
						<a href="https://wa.me/1553894775/?text=Hola,%20tuve%20un%20problema%20con%20la%20plataforma.">
							Asistencia
						</a>
					</Button>
				</DropdownMenuGroup>
				<DropdownMenuGroup className="flex flex-row gap-betweenComponents h-[124px]">
					<LabelButton
						className={labelButtonClass}
						size="full"
						label="QR Cripto"
						icon={<QrCode className="text-icon-navbar" />}
						variant="outline"
						link="/qrs"
					/>
					{!isPos && (
						<LabelButton
							className={labelButtonClass}
							size="full"
							label="Equipos"
							icon={<Users className="text-icon-navbar" />}
							variant="outline"
							link="/teams"
						/>
					)}
				</DropdownMenuGroup>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}

export default BottomBarDropdown
