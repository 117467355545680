import { Calendar } from "lucide-react"
import { Button } from "../../../components/ui/button"
import { Input } from "../../../components/ui/inputs/input"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../../../components/ui/section-header"
import Spacer from "../../../components/ui/spacer"

import PlatformHeader from "../../../components/ui/layouts/platform/PlatformHeader.tsx"

export default function BusinessBody() {
	return (
		<>
			<PlatformHeader title={"Empresa"} variant="secondary" />
			<CenteredBody>
				<SectionHeader
					title="Configurá tu cuenta"
					subtitle="Completá tus datos personales para comenzar a usar nuestra plataforma"
				/>

				<div>
					<div className="grid grid-cols-1 gap-betweenFields">
						{/* Razón social*/}
						<div>
							<Input
								type="text"
								required
								placeholder="Calfeto SA"
								label="Razón social"
							/>
						</div>

						<div className="md:flex-row flex flex-col w-full gap-betweenFields">
							{/* N° de inscripción*/}
							<div className="w-full">
								<Input
									placeholder="xxx-xxxxxx-x"
									label="N° de inscripción"
									required
									helpText="El N° de inscripción se encuentra en la constancia de inscripción de la AFIP"
								/>
							</div>
							{/* Fecha de inscripción */}
							<div className="w-full">
								<Input
									icon={<Calendar />}
									label="Fecha de inscripción"
									required
									placeholder="10/08/05"
									helpText="La fecha de inscripción se encuentra en la constancia de inscripción de la AFIP"
								/>
							</div>
						</div>

						{/* Document Type and CUIT */}
						<div>
							<Input
								label="CUIT/CDI/CIE "
								required
								placeholder="12345678"
								type="leading"
								className="rounded-l-none border-l-0"
							/>
						</div>

						{/* Phone Number */}
						<div>
							<Input
								label="Teléfono"
								required
								type="leading"
								className="rounded-l-none border-l-0"
								leading={
									<div className="flex h-lg rounded-xs px-sm py-xs border border-border-input bg-background-subtle text-h5 text-text-muted font-medium ring-offset-background placeholder:text-muted-foreground cursor-not-allowed rounded-e-none">
										<span className="text-center flex items-center justify-center">
											{"+54"}
										</span>
									</div>
								}
							/>
						</div>

						<Spacer value="300px" axis={"vertical"} />

						<Button type="submit">Siguiente</Button>
					</div>
				</div>
			</CenteredBody>
		</>
	)
}
