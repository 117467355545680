import CenteredBody from "../../ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../../ui/section-header"
import SlidingCard from "../../ui/sliding-card"
import { Heading6 } from "../../ui/texts/TextStyle"

const PluginSuccessModal = ({
	open,
	onClose,
}: {
	open: boolean
	onClose: () => void
}) => {
	return (
		<SlidingCard
			side="center"
			open={open}
			onOpenChange={(open) => {
				if (!open) onClose()
			}}
		>
			<CenteredBody className=" items-center">
				<SectionHeader
					title="¡Felicitaciones!"
					subtitle="Has completado la integración"
				/>
				<img
					src="/dashboard/success_checkmark.svg"
					alt="success"
					className="w-3/4"
				/>
				<Heading6 className="font-normal text-text-muted">
					Ahora solo te queda comunicar en redes que ya aceptas pagos en cripto.
					Podés ver como lo hicieron,{" "}
					<a href="https://talo.com.ar/marketplace" className="underline">
						otros negocios
					</a>
					.
				</Heading6>
			</CenteredBody>
		</SlidingCard>
	)
}

export default PluginSuccessModal
