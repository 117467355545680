import { useNavigate } from "react-router-dom"
import CenteredBody from "./body/CenteredBody"
import { SectionHeader, SectionHeaderProps } from "../../section-header"
import { Section, SectionProps } from "../../section"
import { Children, ReactNode } from "react"

export interface FeatureLayoutProps {
	coverImage: JSX.Element
	sectionHeaderProps: SectionHeaderProps
	children: ReactNode
}

export default function FeatureLayout({
	coverImage,
	sectionHeaderProps,
	children,
}: FeatureLayoutProps) {
	return (
		<CenteredBody>
			<div className=" flex w-full justify-center items-center">
				{coverImage}
			</div>
			<SectionHeader {...sectionHeaderProps} />
			{children}
		</CenteredBody>
	)
}
