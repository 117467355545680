import { Printer } from "lucide-react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../../context/AuthContext"
import { PublicRole, getMaxRole } from "../../../lib/roles"
import { createQr } from "../../../services/qrs"
import SuccessfulQRRegistrationModal from "../../modals/success-qr-registration/SuccessfulQRRegistrationModal"
import LoadableButton from "../../ui/buttons/LoadableButton"
import CenteredBody from "../../ui/layouts/platform/body/CenteredBody"
import { Option } from "../../ui/options/option"
import { Section } from "../../ui/section"
import { SectionHeader } from "../../ui/section-header"
import { QRCryptoItem } from "./QRCryptoCard"
import { WelomeQrCriptoVideo } from "./animations/WelcomeQrCriptoVideo"

enum RequestStatus {
	INITIAL,
	LOADING,
	SUCCESS,
	ERROR,
}

const WelcomeQRCryptoBody = () => {
	const [requestStatus, setRequestStatus] = useState(RequestStatus.INITIAL)
	const [isVisible, setVisible] = useState(false)
	const [qr, setQr] = useState<QRCryptoItem | undefined>(undefined)
	const [downloading, setDownloading] = useState(false)
	const { user } = useAuth()
	const navigate = useNavigate()
	const isPos = getMaxRole(user.roles ?? user.talo_role_list) === PublicRole.POS

	const handleDownload = async () => {
		if (qr) {
			setDownloading(true)
			await import("../../../utils/qr_crypto_download").then((module) => {
				const { handleGeneratePdf } = module
				handleGeneratePdf(qr.id, qr.code, qr.qr_url, true, true)
			})
			setDownloading(false)
		}
	}
	const handleQRCriptoRequest = async () => {
		try {
			setRequestStatus(RequestStatus.LOADING)
			const qr = await createQr(user.user_id, user.team_id)
			if (qr) {
				setQr(qr)
				setRequestStatus(RequestStatus.SUCCESS)
			} else {
				setRequestStatus(RequestStatus.ERROR)
			}
		} catch (err) {
			console.error(err)
			setRequestStatus(RequestStatus.ERROR)
		}
	}
	function getOnClickFromStatus(status: RequestStatus) {
		switch (status) {
			case RequestStatus.INITIAL:
				return handleQRCriptoRequest()
			case RequestStatus.LOADING:
				return undefined
			case RequestStatus.SUCCESS:
				setVisible(true)
				return
			case RequestStatus.ERROR:
				return handleQRCriptoRequest()
		}
	}
	return (
		<CenteredBody className="justify-center items-center gap-betweenSections">
			<SuccessfulQRRegistrationModal
				open={isVisible}
				handleConfirm={() => navigate("/qrs")}
				onClose={() => {
					setVisible(false)
					navigate("/qrs")
				}}
			/>
			<CenteredBody>
				<SectionHeader
					title={getTitleFromReqStatus(requestStatus)}
					subtitle={getSubtitleFromReqStatus(requestStatus, isPos)}
				/>
				{requestStatus === RequestStatus.INITIAL && (
					<div className="overflow-x-hidden w-full z-3 flex content-center justify-center items-center h-[500px] ">
						<WelomeQrCriptoVideo />
					</div>
				)}
			</CenteredBody>
			{requestStatus === RequestStatus.SUCCESS && (
				<Section
					title="Personalizá el QR"
					footer="Se descargará el QR para tu tienda física. El cual deberas imprimir y colocar donde mas te guste. "
				>
					<Option
						title="QR Fijo"
						icon={<Printer></Printer>}
						leading="button"
						buttonProps={{
							isLoading: downloading,
						}}
						buttonText="Descargar"
						onClick={handleDownload}
					></Option>
				</Section>
			)}
			{!isPos && (
				<LoadableButton
					size="full"
					onClick={() => getOnClickFromStatus(requestStatus)}
					isLoading={requestStatus === RequestStatus.LOADING}
				>
					{getButtonTextFromStatus(requestStatus)}
				</LoadableButton>
			)}
		</CenteredBody>
	)
}

function getTitleFromReqStatus(status: RequestStatus) {
	switch (status) {
		case RequestStatus.INITIAL:
			return "Agregá tu QR en caja"
		case RequestStatus.LOADING:
			return "Aguarda, estamos generando tu QR Cripto."
		case RequestStatus.SUCCESS:
			return "Felicitaciones"
		case RequestStatus.ERROR:
			return "Ocurrió un error"
	}
}

function getSubtitleFromReqStatus(
	status: RequestStatus,
	isPos: boolean = false
) {
	switch (status) {
		case RequestStatus.INITIAL:
			if (isPos) {
				return "Tu administrador puede generar un QR para colocar en la caja"
			}
			return "Podrás generar un QR para colocar en la caja de tu comercio."
		case RequestStatus.LOADING:
			return "Por favor, espere mientras generamos tu QR Cripto."
		case RequestStatus.SUCCESS:
			return "Ahora solo queda imprimirlo y colocarlo en el lugar que mas te sirva a vos."
		case RequestStatus.ERROR:
			return "Ocurrió un error al generar tu QR Cripto."
	}
}

function getButtonTextFromStatus(status: RequestStatus) {
	switch (status) {
		case RequestStatus.INITIAL:
			return "Generá tu QR Crypto"
		case RequestStatus.LOADING:
			return "Generando..."
		case RequestStatus.SUCCESS:
			return "Finalizar"
		case RequestStatus.ERROR:
			return "Intentar nuevamente"
	}
}

export default WelcomeQRCryptoBody
