import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useGlobalStore } from "../../hooks/useGlobalStore";
import { PrivateRole, PublicRole } from "../../lib/roles";
import { Skeleton } from "../ui/skeleton";

const ProtectedRoute = ({
  children,
  requiresVerification = true,
  requiresKyc = true,
  minimumPrivateRole,
  minimumPublicRole,
}: {
  children: React.ReactNode;
  requiresVerification?: boolean;
  requiresKyc?: boolean;
  minimumPrivateRole?: PrivateRole;
  minimumPublicRole?: PublicRole;
}) => {
  const { user, hasPrivilege, kyc } = useAuth();
  const { globalStore, setGlobalStore } = useGlobalStore();
  const location = useLocation();

  useEffect(() => {
    if (!user) {
      setGlobalStore((prev) => ({ ...prev, deepLink: location.pathname }));
    } else if (
      requiresKyc &&
      !kyc?.data?.kyc_status &&
      new Date(user.creation_timestamp) > new Date(2024, 7, 21)
    ) {
      setGlobalStore((prev) => ({
        ...prev,
        deepLink: location.pathname + location.search,
      }));
    }
  }, [user, kyc?.data?.kyc_status, setGlobalStore, location.pathname, location.search]);

  // Handle redirections
  if (!user) {
    return <Navigate to="/login" replace={true} />;
  }

  if (requiresVerification && !user.emailVerified && !user.email_verified) {
    return <Navigate to="/verify-email" replace={true} />;
  }

  if (requiresKyc && kyc.isLoading) {
	//TODO :  TRAVE
	return <Skeleton></Skeleton>;
  }

  const kycEpoch = new Date(2024, 7, 21);
  const userCreationDate = new Date(user.creation_timestamp);

  if (requiresKyc && !kyc?.data?.kyc_status && userCreationDate > kycEpoch && (user.roles && user.roles[0] === "owner")) {

    return <Navigate to="/onboarding" replace={true} />;
  }

  if (requiresKyc && userCreationDate < kycEpoch) {
    return <>{children}</>;
  }

  // if (requiresKyc && !user.pollux_account_id && (user.roles && user.roles[0] === "owner")) {

  //   return <Navigate to="/onboarding/payment-methods" replace={true} />;
  // }

  if (
    !hasPrivilege(
      minimumPrivateRole ?? PrivateRole.CLONE,
      minimumPublicRole ?? PublicRole.POS
    )
  ) {
    return <Navigate to="/no-privilege" replace={true} />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
