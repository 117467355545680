import { ReactNode } from "react"
import BodyTitle from "./BodyTitle"
import { Separator } from "../../../ui/separator"

const BodyBlock = ({
	children,
	title,
	isFirst = false,
}: {
	children: ReactNode | ReactNode[]
	title: string
	isFirst?: boolean
}) => {
	return (
		<div>
			{!isFirst && (
				<Separator className="my-belowSectionHeader border-solid border border-border-onModal" />
			)}
			<BodyTitle title={title} />
			{children}
		</div>
	)
}

export default BodyBlock
