import { ReactNode } from "react"
import Col from "../../column"
import { cn } from "../../../../../lib/utils"

const CenteredBody = ({
	children,
	className,
}: {
	children: ReactNode | ReactNode[]
	className?: string
}) => {
	return (
		<Col
			className={cn(
				"max-w-[696px] w-full mx-auto flex flex-col gap-betweenSections pb-20",
				className
			)}
		>
			{children}
		</Col>
	)
}

export default CenteredBody
