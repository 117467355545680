import BodyItem from "./BodyItem"

const DateInfo = ({ date, time }: { date: string; time: string }) => {
	return (
		<>
			{date && <BodyItem name="Fecha" value={date} />}
			{time && <BodyItem name="Hora" value={time} />}
		</>
	)
}

export default DateInfo
