import { SquareMenu } from "lucide-react"
import { TiendanubePaymentInfo } from "../../../../tables/transactions-table/columns"
import { Button } from "../../../../ui/button"
import Spacer from "../../../../ui/spacer"
import BodyBlock from "../BodyBlock"
import BodyItem from "../BodyItem"

const TiendanubePaymentDetails = ({
	info,
	store_url,
}: {
	info: TiendanubePaymentInfo
	store_url?: string
}) => {
	return (
		<BodyBlock title="Tiendanube">
			<BodyItem name="ID de orden" value={`${info.order_id}`} />
			<BodyItem name="Número de orden" value={`${info.order_number}`} />
			{info.client_email && (
				<BodyItem
					name="Email del cliente"
					value={info.client_email}
					overflow={true}
				/>
			)}
			{store_url && (
				<Button asChild variant="outline" size="full">
					<a
						href={`https://${store_url}/admin/v2/orders/${info.order_id}`}
						target="_blank"
					>
						Ir a la orden
						<Spacer axis="horizontal" type="betweenTexts" />
						<SquareMenu className="size-icon" />
					</a>
				</Button>
			)}
		</BodyBlock>
	)
}

export default TiendanubePaymentDetails
