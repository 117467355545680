import { Navigate } from "react-router-dom"
import { useAuth } from "../../context/AuthContext"
import { useEffect } from "react"
import Row from "../../components/ui/layouts/row"
import { Progress } from "../../components/ui/progress"

export default function PaymentMethodsWrapper({
	children,
	progressPercentage,
}: {
	children: React.ReactNode
	progressPercentage: number
}) {
	const { stores, kyc } = useAuth()

	useEffect(() => {}, [kyc.data?.kyc_status, stores])
	// if (kyc.data?.kyc_status === "pending" && stores && stores.length > 0 && !URLSearchParams.get("code")) {
	// 	return <Navigate to="/onboarding/success" replace={true} />
	// }
	if((!kyc.isLoading || !kyc.isFetching) && !kyc.data?.kyc_status){
		return <Navigate to="/onboarding" replace={true} />
	}
	return (
		<>
			<Row className="gap-betweenTexts">
				<div className="h-2 w-24 bg-black rounded-full" />
				<Progress variant="secondary" value={progressPercentage} />
			</Row>
			{children}
		</>
	)
}
