import { Heading3, Heading6 } from "./texts/TextStyle"
import { cn } from "../../lib/utils"

export interface SectionHeaderProps {
	title: string
	subtitle?: string
	className?: string
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
	title,
	subtitle,
	className,
}) => {
	const classes = className
		?.split(" ")
		.reduce<{ title: string[]; subtitle: string[]; main: string[] }>(
			(acc, curr) => {
				if (curr.startsWith("title:")) {
					acc.title.push(curr.replace("title:", ""))
				} else if (curr.startsWith("subtitle:")) {
					acc.subtitle.push(curr.replace("subtitle:", ""))
				} else {
					acc.main.push(curr)
				}
				return acc
			},
			{ main: [], title: [], subtitle: [] }
		) || { main: [], title: [], subtitle: [] }

	return (
		<div
			className={cn("grid w-full text-start gap-betweenTexts", ...classes.main)}
		>
			<Heading3 className={cn("font-semibold", ...classes.title)}>
				{title}
			</Heading3>
			{subtitle && (
				<Heading6
					className={cn("font-medium text-text-muted", ...classes.subtitle)}
				>
					{subtitle}
				</Heading6>
			)}
		</div>
	)
}

export { SectionHeader }
