import CenteredBody from "../../components/ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../../components/ui/section-header"
import LandingLayout from "../../components/ui/layouts/landing/LandingLayout"
import { Heading3 } from "../../components/ui/texts/TextStyle"

const PrivacyPolicy: React.FC = () => {
	return (
		<LandingLayout>
			<CenteredBody className="h-full justify-center items-center">
				<SectionHeader
					title="Política de Privacidad de Talo Pay Inc"
					subtitle="Ultima actualización: 10 de Marzo 2023"
				/>

				<div className=" py-8">
					<section className="mb-8">
						<Heading3 className=" font-semibold mb-4">1. Introducción</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							En Talo Pay Inc., nos comprometemos a proteger la privacidad de
							nuestros usuarios. Esta política de privacidad describe cómo
							recopilamos, utilizamos y protegemos su información personal
							cuando utiliza nuestros servicios.
						</p>
					</section>

					<section className="mb-8">
						<Heading3 className="font-semibold mb-4">
							2. Información que Recopilamos
						</Heading3>
						<ul className="list-disc list-outside pl-5 text-gray-700 text-h5 font-normal leading-relaxed">
							<li>
								<strong>Datos Personales:</strong> Recopilamos su nombre,
								dirección de correo electrónico, información bancaria, y otros
								datos necesarios para la prestación de nuestros servicios.
							</li>
							<li>
								<strong>Datos Recopilados Automáticamente:</strong> Recopilamos
								información técnica como direcciones IP y cookies cuando usted
								interactúa con nuestra plataforma.
							</li>
							<li>
								<strong>Datos de Uso:</strong> Registramos su historial de
								transacciones y uso de nuestros servicios para mejorar la
								experiencia del usuario.
							</li>
						</ul>
					</section>

					<section className="mb-8">
						<Heading3 className=" font-semibold mb-4">
							3. Uso de la Información
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							Utilizamos su información para proporcionar nuestros servicios,
							mejorar la plataforma, y comunicarnos con usted. También
							utilizamos sus datos para cumplir con las obligaciones legales.
						</p>
					</section>

					<section className="mb-8">
						<Heading3 className=" font-semibold mb-4">
							4. Compartición de la Información
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							Podemos compartir su información con proveedores de servicios y
							socios comerciales. También podemos transferir sus datos fuera del
							país con las garantías adecuadas de protección.
						</p>
					</section>

					<section className="mb-8">
						<Heading3 className=" font-semibold mb-4">
							5. Protección de la Información
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							Implementamos medidas de seguridad técnicas y organizativas para
							proteger su información. Retenemos sus datos solo por el tiempo
							necesario para cumplir con nuestras obligaciones legales y de
							servicio.
						</p>
					</section>

					<section className="mb-8">
						<Heading3 className="text-2xl font-semibold mb-4">
							6. Derechos de los Usuarios
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							Usted tiene derecho a acceder, rectificar, suprimir, y portar sus
							datos personales. También puede oponerse al tratamiento de sus
							datos o solicitar su limitación.
						</p>
					</section>

					<section className="mb-8">
						<Heading3 className="text-2xl font-semibold mb-4">
							7. Uso de Cookies y Tecnologías Similares
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							Utilizamos cookies para mejorar su experiencia en nuestra
							plataforma. Puede gestionar sus preferencias de cookies a través
							de su navegador.
						</p>
					</section>

					<section className="mb-8">
						<Heading3 className="text-2xl font-semibold mb-4">
							8. Transferencia de Negocios
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							En caso de fusión o venta, su información puede ser transferida a
							la entidad sucesora.
						</p>
					</section>

					<section className="mb-8">
						<Heading3 className="text-2xl font-semibold mb-4">
							9. Cambios a esta Política de Privacidad
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							Podemos actualizar esta política de privacidad y le notificaremos
							cualquier cambio significativo. El uso continuado de nuestros
							servicios constituye su aceptación de la política actualizada.
						</p>
					</section>

					<section className="mb-8">
						<Heading3 className="text-2xl font-semibold mb-4">
							10. Contacto
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							Para cualquier consulta sobre esta política de privacidad, puede
							contactarnos en{" "}
							<a className="text-text-interactive">info@talo.com.ar</a>.
						</p>
					</section>

					<section>
						<Heading3 className="text-2xl font-semibold mb-4">
							11. Legislación Aplicable y Jurisdicción
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							Esta política de privacidad se rige por las leyes de la República
							Argentina. Cualquier disputa relacionada se resolverá en los
							tribunales de la Ciudad Autónoma de Buenos Aires.
						</p>
					</section>
				</div>
			</CenteredBody>
		</LandingLayout>
	)
}

export default PrivacyPolicy
