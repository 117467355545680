import { KeyRound, Puzzle, RectangleEllipsis, Trash2 } from "lucide-react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Alert } from "../../../components/ui/alert"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import { Option } from "../../../components/ui/options/option"
import { Section } from "../../../components/ui/section"
import { SectionHeader } from "../../../components/ui/section-header"
import { useAnalytics } from "../../../context/AnalyticsContext"
import { useAuth } from "../../../context/AuthContext"
import {
	StoreType,
	updateUser as serviceUpdateUser,
} from "../../../services/users"
import { fiatCurrencies } from "../../../utils/currencies"
import BrlCover from "./PaymentsCover"
import FeatureLayout from "../../../components/ui/layouts/platform/FeatureLayout"

export interface Integration {
	title: string
	icon: React.ReactNode
}

const integrationsAvailable: { [key in StoreType]: Integration } = {
	tiendanube: {
		title: "Tiendanube + Talo Pay",
		icon: <img src="/dashboard/plugins/tiendanube.svg" className="size-full" />,
	},
	woocommerce: {
		title: "Woocommerce + Talo Pay",
		icon: (
			<img src="/dashboard/plugins/woocommerce.svg" className="size-full" />
		),
	},
}

export default function Reales() {
	const navigate = useNavigate()
	const { user, stores, updateUser } = useAuth()
	const [isTiendanubeInstalled, setIsTiendanubeInstalled] = useState(false)
	const [isWooCommerceInstalled, setIsWooCommerceInstalled] = useState(false)
	const [fiatPaymentsEnabled, setFiatPaymentsEnabled] = useState(
		user?.fiat_payments_enabled || false
	)

	const { logFiatPaymentsToggled } = useAnalytics()

	const handleSwitchChange = async (checked: boolean) => {
		setFiatPaymentsEnabled(checked)
		const res = await serviceUpdateUser(user.id, {
			fiat_payments_enabled: checked,
		})
		if (res && res.status >= 200 && res.status < 300) {
			updateUser(res.data.data)
			logFiatPaymentsToggled({
				user_id: user?.user_id,
				is_enabled: checked,
				toggled_at: new Date().toISOString(),
			})
		}
	}

	useEffect(() => {
		if (stores) {
			if (process.env.REACT_APP_TIENDANUBE_APP_ID === undefined) {
				return
			}

			const isTiendanubeStore = stores.some(
				(store: any) =>
					store.store_type === StoreType.TIENDANUBE &&
					store.store_apps.find(
						(app: any) =>
							app.app_id === process.env.REACT_APP_TIENDANUBE_APP_ID ||
							app.app_id === process.env.REACT_APP_TIENDANUBE_HOMO_APP_ID
					)
			)

			if (isTiendanubeStore) {
				setIsTiendanubeInstalled(true)
			}

			const isWooCommerceStore = stores.some(
				(store: any) => store.store_type === StoreType.WOOCOMMERCE
			)

			if (isWooCommerceStore) {
				setIsWooCommerceInstalled(true)
			}
		}
	}, [stores])

	return (
		<FeatureLayout
			coverImage={<BrlCover />}
			sectionHeaderProps={{
				title: "Pagos en reales",
				subtitle:
					"Aceptá transferencias por pix y permití que los turistas de Brasil te paguen con su medio de pago favorito.",
			}}
		>
			<Section>
				<Option
					title="Reales"
					description="BRL"
					icon={<img src={fiatCurrencies.BRL.flagPath} alt="BRL" />}
					iconInside={false}
					leading="switch"
					switchChecked={fiatPaymentsEnabled}
					onCheckedChange={handleSwitchChange}
				/>
				<Alert
					variant="link"
					icon={
						<div className="rounded-full [&>*]:size-[18px] [&>*]:stroke-[2.5px] group-hover:animate-[wiggle_1s_ease-in-out_infinite] size-md bg-background-strong grid justify-center items-center">
							<RectangleEllipsis />
						</div>
					}
					title={"Recibir monedas digitales"}
					description={
						"El dinero se acreditara en la moneda digital USDT. Esta se podrá retirar a cualquier billetera en la red de Polygon. Para más información visitar la documentación."
					}
					buttonText="Abrir documentación web"
					buttonLink="https://flowbite.com/docs/typography/links/"
				/>
			</Section>

			{isTiendanubeInstalled || isWooCommerceInstalled ? (
				<Section
					title="Integraciones activas"
					footer="Para agregar nueva integraciones. Prueba desvinculando la activa."
				>
					{isTiendanubeInstalled && (
						<Option
							key={integrationsAvailable.tiendanube.title}
							title={integrationsAvailable.tiendanube.title}
							description="Activa"
							icon={integrationsAvailable.tiendanube.icon}
							leading="div"
							div={null}
							iconInside={false}
							className="bg-background description:text-text-success"
						/>
					)}
					{isWooCommerceInstalled && (
						<Option
							key={integrationsAvailable.woocommerce.title}
							title={integrationsAvailable.woocommerce.title}
							description="Activa"
							icon={integrationsAvailable.woocommerce.icon}
							leading="div"
							div={null}
							iconInside={false}
							className="bg-background description:text-text-success"
						/>
					)}
					<Option
						title="Credenciales"
						icon={<KeyRound />}
						leading="arrow"
						className="description:text-text-success"
						onClick={() => navigate("/settings/plugins/credentials")}
					/>
				</Section>
			) : (
				<Section title="Conectá tu cuenta">
					<Option
						title="Integraciones"
						description="Conectá tu tienda online"
						icon={<Puzzle />}
						leading="arrow"
						onClick={() => navigate("/settings/payment-methods/pix/plugins")}
					/>
				</Section>
			)}
		</FeatureLayout>
	)
}
