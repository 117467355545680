import { SettingsItemRow } from "../../../components/app/settings/SettingsItem"
import { Section } from "../../../components/ui/section"
import { useAuth } from "../../../context/AuthContext"
import { PrivateRole, PublicRole } from "../../../lib/roles"
import { SettingsSectionItem } from "../../settings/items"

const PluginsSection = ({
	subSections,
}: {
	subSections: SettingsSectionItem[]
}) => {
	const { hasPrivilege } = useAuth()

	return subSections
		.filter(
			(subs) =>
				hasPrivilege(
					subs.minimumPrivateRole ?? PrivateRole.CLONE,
					subs.minimumPublicRole ?? PublicRole.POS
				) &&
				subs.subItems.some((subItem) =>
					hasPrivilege(
						subItem.minimumPrivateRole ?? PrivateRole.CLONE,
						subItem.minimumPublicRole ?? PublicRole.POS
					)
				)
		)
		.map((section) => (
			<Section
				title={section.title}
				footer={section.description}
				key={section.title}
			>
				{section.subItems
					.filter((subItem) =>
						hasPrivilege(
							subItem.minimumPrivateRole ?? PrivateRole.CLONE,
							subItem.minimumPublicRole ?? PublicRole.POS
						)
					)
					.map((item) => (
						<SettingsItemRow
							link={item.link}
							key={item.title}
							title={item.title}
							icon={item.icon}
							iconInside={item.iconInside}
						/>
					))}
			</Section>
		))
}

export default PluginsSection
