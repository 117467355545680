import { formatPriceAmount } from "../../../../utils/numbers"
import { TableSubTransaction } from "../../../tables/transactions-table/columns"
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "../../../ui/accordion"
import BodyItem from "./BodyItem"

const TransactionsAccordion = ({
	transactions,
}: {
	transactions: TableSubTransaction[]
}) => {
	return (
		<Accordion
			type="single"
			collapsible
			className="border border-border-onModal rounded-sm px-betweenComponents"
		>
			{transactions?.map((transaction: TableSubTransaction) => (
				<AccordionItem value={transaction.transactionId}>
					<AccordionTrigger>
						{formatPriceAmount(transaction.amountReadable)}{" "}
						{transaction.currency}
					</AccordionTrigger>
					<AccordionContent>
						<BodyItem
							name="Hash de transacción"
							value={transaction.transactionHash}
							copy={true}
							overflow={true}
						/>
						<BodyItem
							name="Dirección de origen"
							value={transaction.senderAddress}
							copy={true}
							overflow={true}
						/>
						<BodyItem name="Fecha" value={transaction.date} />
						<BodyItem name="Hora" value={transaction.time} />
					</AccordionContent>
				</AccordionItem>
			))}
		</Accordion>
	)
}

export default TransactionsAccordion
