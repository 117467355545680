import { useEffect, useLayoutEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "../../components/ui/button"
import CenteredBody from "../../components/ui/layouts/platform/body/CenteredBody"
import { Option } from "../../components/ui/options/option"
import { Section } from "../../components/ui/section"
import { SectionHeader } from "../../components/ui/section-header"
import Spacer from "../../components/ui/spacer"
import { useAuth } from "../../context/AuthContext"
import { useFlags } from "../../context/FlagsContext"
import { redirectToEndOfOnboarding } from "../../lib/utils"
import { StoreType } from "../../services/users"
import { fiatCurrencies } from "../../utils/currencies"
import { CryptoAllLogo } from "../../utils/withdrawCurrencies"

const PaymentMethodsBody = ({}: {}) => {
	const navigate = useNavigate()
	const { stores, user } = useAuth()
	const [hasIntegration, setHasIntegration] = useState(false)
	const { getFlag } = useFlags()

	useLayoutEffect(() => {
		const kycEpoch = new Date(2024, 7, 21)
		const userCreationDate = new Date(user.creation_timestamp)

		if (userCreationDate < kycEpoch) {
			navigate("/settings/payment-methods/transfer")
		}
	})

	useEffect(() => {
		if (stores) {
			if (process.env.REACT_APP_TIENDANUBE_CVU_APP_ID === undefined) {
				return
			}

			const isTiendanubeStore = stores.some(
				(store: any) =>
					store.store_type === StoreType.TIENDANUBE &&
					store.store_apps.find(
						(app: any) =>
							app.app_id === process.env.REACT_APP_TIENDANUBE_CVU_APP_ID
					)
			)

			if (isTiendanubeStore) {
				setHasIntegration(true)
			}
		}
	}, [stores])
	const flag = getFlag("show_transfer_ars").asBoolean()
	return (
		<CenteredBody>
			<SectionHeader
				title="Opciones para tu cliente en el checkout"
				subtitle="Acá podrás personalizar las opciones de pago estarán disponibles para tus clientes durante el proceso de compra."
			/>

			<Section
				title="Monedas tradicionales"
				footer="Los costos por transacción son diferentes para cada moneda"
			>
				<Option
					title={"ARS" + (!flag ? " (Proximamente)" : "")}
					icon={<img src={fiatCurrencies.ARS.flagPath} alt="ARS Logo" />}
					iconInside={false}
					description="Transferencias automáticas"
					leading="arrow"
					disabled={!flag}
					onClick={() => {
						navigate("/onboarding/payment-methods/transfer")
					}}
				/>
				<Option
					title={"BRL"}
					icon={<img src={fiatCurrencies.BRL.flagPath} alt="BRL Logo" />}
					iconInside={false}
					description="Reales a través de pix"
					leading="arrow"
					onClick={() => {
						navigate("/onboarding/payment-methods/pix")
					}}
				/>
			</Section>
			<Section title="Monedas digitales (criptomonedas)">
				<Option
					title={"Criptomonedas"}
					icon={<CryptoAllLogo></CryptoAllLogo>}
					iconInside={false}
					description="A traves de la red"
					leading="arrow"
					onClick={() => {
						navigate("/onboarding/payment-methods/crypto")
					}}
				/>
			</Section>
			<Spacer axis={"vertical"} type="betweenSections"></Spacer>
			{
				<Button
					size="full"
					variant={hasIntegration ? "default" : "outline"}
					onClick={() => {
						redirectToEndOfOnboarding(navigate, user)
					}}
				>
					{hasIntegration ? "Finalizar" : "Omitir"}
				</Button>
			}
		</CenteredBody>
	)
}

export default PaymentMethodsBody
