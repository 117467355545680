import { Price } from "@crypto-payment-gateway/models"
import { useState } from "react"
import { useAnalytics } from "../../../context/AnalyticsContext"
import { PaymentOption } from "../../../pages/charge/charge-wizard-types"
import { Quote } from "../../../pages/checkout/id"
import { fiatCurrencies } from "../../../utils/currencies"
import { useMediaQuery } from "../../../utils/useMediaQuery"
import { Wallet, WalletButton, getWalletLink } from "../../../utils/wallets"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs"
import CryptoPayment from "./CryptoPayment"
import FiatPayment from "./FiatPayment"
import TransferPaymentCheckout from "./TransferPaymentCheckout"

const PaymentWizard = ({
	quotes,
	paymentId,
	pendingHandler,
	price,
	userInfo,
	paymentOptions,
}: {
	quotes: Quote[]
	paymentId: string
	pendingHandler: () => void
	price: Price
	userInfo: any
	paymentOptions: PaymentOption[]
}) => {
	const [quote, setQuote] = useState<Quote | undefined>()
	const [wallet, setWallet] = useState<WalletButton | undefined>()
	const { logCurrencySelected, logWalletSelected } = useAnalytics()

	const isMd = useMediaQuery(960)

	const handleSelectCurrency = (currency: string) => {
		const current_quote = quotes.find((q: Quote) => q.currency === currency)
		if (current_quote) {
			logCurrencySelected({
				currency: currency,
				payment_id: paymentId,
				network: current_quote.network,
				currency_selected_at: new Date().toISOString(),
			})
		}
		setQuote(current_quote)
	}
	const handleGoBack = () => {
		if (wallet) {
			setWallet(undefined)
		}
	}

	const handleWalletClick = (wallet: Wallet) => {
		const link = getWalletLink(wallet, quote)
		setWallet({ ...wallet, link: link })
		logWalletSelected({
			wallet: wallet.id,
			payment_id: paymentId,
			network: quote?.network || "",
			currency: quote?.currency || "",
			wallet_selected_at: new Date().toISOString(),
		})

		if (link && isMd) {
			window.open(link)
		}
	}
	if (paymentOptions.includes(PaymentOption.TRANSFER)) {
		return (
			<TransferPaymentCheckout
				quotes={quotes}
				pendingHandler={pendingHandler}
				price={price}
				userInfo={userInfo}
			/>
		)
	}

	if (userInfo?.fiat_payments_enabled) {
		return (
			<Tabs defaultValue="fiat" className="mt-8">
				<TabsList>
					<TabsTrigger value="fiat">Tradicional</TabsTrigger>
					<TabsTrigger value="crypto">Crypto</TabsTrigger>
				</TabsList>
				<TabsContent value="fiat">
					<FiatPayment
						fiatQuotes={getFiatQuotes(quotes)}
						pendingHandler={pendingHandler}
						price={price}
					/>
				</TabsContent>
				<TabsContent value="crypto">
					<CryptoPayment
						handlers={{
							handleSelectCurrency,
							handleGoBack,
							handleWalletClick,
							pendingHandler,
						}}
						quote={quote}
						wallet={wallet}
						quotes={quotes}
					/>
				</TabsContent>
			</Tabs>
		)
	} else {
		return (
			<CryptoPayment
				handlers={{
					handleSelectCurrency,
					handleGoBack,
					handleWalletClick,
					pendingHandler,
				}}
				quote={quote}
				wallet={wallet}
				quotes={quotes}
			/>
		)
	}
}

function getFiatQuotes(quotes: Quote[]): Quote[] {
	return quotes.filter((q) => Object.keys(fiatCurrencies).includes(q.currency))
}

export default PaymentWizard
