import CenteredBody from "../../components/ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../../components/ui/section-header"
import LandingLayout from "../../components/ui/layouts/landing/LandingLayout"
import { Heading3 } from "../../components/ui/texts/TextStyle"

const TermsOfService: React.FC = () => {
	return (
		<LandingLayout>
			<CenteredBody className="h-full justify-center items-center">
				<SectionHeader
					title="Términos y Condiciones de Talo Pay Inc"
					subtitle="Última actualización: 10 de Marzo 2023"
				/>

				<div className="py-8">
					<section className="mb-8">
						<Heading3 className="font-semibold mb-4">1. Introducción</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							Bienvenido a Talo Pay Inc. Al utilizar nuestros servicios, usted
							acepta estos términos y condiciones.
						</p>
					</section>

					<section className="mb-8">
						<Heading3 className="font-semibold mb-4">2. Definiciones</Heading3>
						<ul className="list-disc list-outside pl-5 text-gray-700 text-h5 font-normal leading-relaxed">
							<li>
								<strong>Usuario:</strong> Individuos o entidades que utilizan
								nuestros servicios.
							</li>
							<li>
								<strong>Servicios:</strong> Creación y gestión de CVUs
								automáticos para órdenes de pago.
							</li>
						</ul>
					</section>

					<section className="mb-8">
						<Heading3 className="font-semibold mb-4">
							3. Aceptación de los Términos
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							Al utilizar nuestros servicios, usted acepta estar sujeto a estos
							términos y condiciones.
						</p>
					</section>

					<section className="mb-8">
						<Heading3 className="font-semibold mb-4">
							4. Registro y Cuentas de Usuario
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							Para utilizar nuestros servicios, el usuario debe registrarse
							proporcionando datos válidos y manteniendo la confidencialidad de
							su cuenta.
						</p>
					</section>

					<section className="mb-8">
						<Heading3 className="font-semibold mb-4">
							5. Modificaciones
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							Nos reservamos el derecho de modificar estos términos en cualquier
							momento. Las modificaciones se notificarán a los usuarios.
						</p>
					</section>

					<section className="mb-8">
						<Heading3 className="font-semibold mb-4">
							6. Servicios Ofrecidos
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							Talo Pay Inc. ofrece servicios de creación y gestión de pagos en
							múltiples monedas.
						</p>
					</section>

					<section className="mb-8">
						<Heading3 className="font-semibold mb-4">
							7. Condiciones de Uso
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							Los usuarios deben utilizar nuestros servicios conforme a la ley y
							no para actividades ilícitas.
						</p>
					</section>

					<section className="mb-8">
						<Heading3 className="font-semibold mb-4">
							8. Pagos y Tarifas
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							Las tarifas aplicables se describen en nuestra página web. Los
							métodos de pago aceptados son criptomonedas, PIX y transferencia
							bancaria.
						</p>
					</section>

					<section className="mb-8">
						<Heading3 className="font-semibold mb-4">
							9. Verificación de Identidad y KYC
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							Se requiere la verificación de identidad para el uso de nuestros
							servicios. Los usuarios deben cumplir con los requisitos de KYC.
						</p>
					</section>

					<section className="mb-8">
						<Heading3 className="font-semibold mb-4">
							10. Política de Privacidad
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							Protegemos los datos personales conforme a nuestra política de
							privacidad disponible en nuestra página web.
						</p>
					</section>

					<section className="mb-8">
						<Heading3 className="font-semibold mb-4">
							11. Propiedad Intelectual
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							Todos los derechos sobre el software y los contenidos pertenecen a
							Talo Pay Inc.
						</p>
					</section>

					<section className="mb-8">
						<Heading3 className="font-semibold mb-4">
							12. Limitación de Responsabilidad
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							Talo Pay Inc. no se hace responsable por daños indirectos,
							incidentales o consecuentes.
						</p>
					</section>

					<section className="mb-8">
						<Heading3 className="font-semibold mb-4">
							13. Cierre de Cuentas y Sanciones
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							Nos reservamos el derecho de cerrar cuentas en caso de
							incumplimiento de estos términos.
						</p>
					</section>

					<section className="mb-8">
						<Heading3 className="font-semibold mb-4">
							14. Impuestos y Tarifas
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							El usuario es responsable de los impuestos asociados con el uso de
							nuestros servicios.
						</p>
					</section>

					<section className="mb-8">
						<Heading3 className="font-semibold mb-4">15. Indemnidad</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							El usuario indemnizará a Talo Pay Inc. por cualquier reclamo
							resultante del uso indebido de nuestros servicios.
						</p>
					</section>

					<section className="mb-8">
						<Heading3 className="font-semibold mb-4">
							16. Domicilios y Notificaciones
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							Talo Pay Inc. tiene su domicilio en Monasterio 1375, Vicente
							Lopez, Vicente Lopez, Provincia de Buenos Aires, Argentina. Las
							notificaciones oficiales se enviarán a{" "}
							<a className="text-text-interactive">info@talo.com.ar</a>.
						</p>
					</section>

					<section>
						<Heading3 className="font-semibold mb-4">
							17. Ley Aplicable y Jurisdicción
						</Heading3>
						<p className="text-gray-700 text-h5 font-normal leading-relaxed">
							Estos términos se rigen por las leyes de la República Argentina.
							Cualquier disputa se resolverá en los tribunales de la Ciudad
							Autónoma de Buenos Aires.
						</p>
					</section>
				</div>
			</CenteredBody>
		</LandingLayout>
	)
}

export default TermsOfService
