import { Loader } from "lucide-react"

const QrWithNoActivePaymentBody = () => {
	return (
		<div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<img
					src="/logos/isotipo_black.svg"
					height="100%"
					style={{ maxHeight: 100 }}
					alt="talo isologo"
				/>
			</div>

			<p className="text-h3 font-medium">ESTAMOS ESPERANDO</p>
			<p className="text-h3 font-medium text-text-success">
				QUE EL COMERCIO CARGUE EL PRECIO
			</p>

			<Loader className="animate-spin" />
		</div>
	)
}

export default QrWithNoActivePaymentBody
