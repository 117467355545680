import { TableTransactionStatus } from "../../../tables/transactions-table/columns"
import {
	statusDescriptions,
	statusIcons,
	statusTranslations,
} from "../../../tables/transactions-table/columns/statusCell"
import { Heading5, Heading6 } from "../../../ui/texts/TextStyle"

const PaymentStatusRow = ({ status }: { status: TableTransactionStatus }) => {
	return (
		<div className="flex flex-row items-center text-text bg-background-subtle p-md rounded-md">
			<div className="size-fit pr-betweenComponents">{statusIcons[status]}</div>
			<div className="flex flex-col">
				<Heading5 className="font-medium pb-[4px]">
					{statusTranslations[status]}
				</Heading5>

				{status === "UNDERPAID" || status === "OVERPAID" ? (
					<Heading6 className="text-text-muted font-medium">
						El monto recibido parece ser{" "}
						<b className="text-text font-bold">
							{status === "UNDERPAID" ? "inferior" : "superior"}
						</b>{" "}
						a lo solicitado, puede contactarse con el comprador
					</Heading6>
				) : (
					(status === "SUCCESS" ||
						status === "SENT" ||
						status === "PENDING") && (
						<Heading6 className="text-text-muted font-medium">
							{statusDescriptions[status]}
						</Heading6>
					)
				)}
			</div>
		</div>
	)
}

export default PaymentStatusRow
