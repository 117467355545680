import { useNavigate } from "react-router-dom"
import PlatformHeader from "../../../components/ui/layouts/platform/PlatformHeader.tsx"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody.tsx"
import Row from "../../../components/ui/layouts/row.tsx"
import { Progress } from "../../../components/ui/progress"
import Success from "../Success"
import FirstForm from "./BasicInfoForm"
import DniUpload from "./DniUpload"
import FacialVerification from "./FacialVerification.tsx"
import SpecialAgreementsBody from "./SpecialAgreementsForm.tsx"
import {
	OnboardingPersonalSteps,
	OnboardingPersonalWizardData,
} from "./onboard-personal-wizard-types.ts"
import { platformHeader } from "../../../components/ui/layouts/layout-sizes.ts"

export default function PersonalBody({
	setWizardData,
	wizardData,
}: {
	setWizardData: (data: OnboardingPersonalWizardData) => void
	wizardData: OnboardingPersonalWizardData
}) {
	const navigate = useNavigate()

	const body = {
		[OnboardingPersonalSteps.SpecialAgreements]: (
			<SpecialAgreementsBody
				setWizardData={setWizardData}
				wizardData={wizardData}
			/>
		),
		[OnboardingPersonalSteps.BasicInfo]: (
			<FirstForm setWizardData={setWizardData} wizardData={wizardData} />
		),
		[OnboardingPersonalSteps.Success]: <Success />,
	}

	return (
		<>
			<PlatformHeader
				title={"Personal"}
				variant="secondary"
				onBackClick={() => {
					if (wizardData.step === OnboardingPersonalSteps.SpecialAgreements) {
						navigate("/onboarding")
					}
					setWizardData({
						...wizardData,
						step: wizardData.step - 1,
					})
				}}
			/>
			<CenteredBody className={`${platformHeader.paddingX.class} pt-4`}>
				<Row className="gap-betweenTexts">
					{/* <div className="h-2 w-24 bg-black rounded-full" /> */}
					<Progress
						variant="secondary"
						value={wizardData.completionPercentage}
					/>
				</Row>
				{body[wizardData.step]}
			</CenteredBody>
		</>
	)
}
