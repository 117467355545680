import BalanceSection from "../../../components/app/dashboard/BalanceSection"
import EmptyWallet from "../../../components/app/wallet/EmptyWallet"
import { Button } from "../../../components/ui/button"
import Col from "../../../components/ui/layouts/column"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import Row from "../../../components/ui/layouts/row"
import CurrencyOption from "../../../components/ui/options/CurrencyOption"
import SectionTitle from "../../../components/ui/titles/SectionTitle"
import { useAuth } from "../../../context/AuthContext"
import { PublicRole, getMaxRole, isOwner } from "../../../lib/roles"
import { Link } from "react-router-dom"
import {
	GroupedBalanceItem,
	groupBalancesByCurrency,
} from "../../../utils/balances"
import { currencies } from "../../../utils/currencies"
import { ArrowDownRight, ArrowUpLeft } from "lucide-react"
import useBreakpoint from "../../../hooks/useBreakpoint"
import { BackgroundGradientAnimation } from "../../../components/ui/background-gradient-animation"

const WalletBody = () => {
	const { balances } = useAuth()
	const { user } = useAuth()
	const isOwner =
		getMaxRole(user.roles ?? user.talo_role_list) === PublicRole.OWNER
	const groupedBalances = groupBalancesByCurrency(balances ?? [])
	const noBalance = groupedBalances.every(
		(balance) => balance.amountDesired === 0
	)
	var body
	if (!balances) {
		//TODO trave: falta un loader
	} else {
		if (noBalance) {
			body = <EmptyWallet />
		} else {
			body = (
				<Col className="gap-betweenOptions">
					{groupedBalances.map((balance: GroupedBalanceItem) => (
						<CurrencyOption
							className="border-0"
							key={balance.currency}
							{...balance}
							name={currencies[balance.currency]?.name}
							logoPath={`/currencies/${balance.currency.toLowerCase()}-icon.svg`}
						/>
					))}
				</Col>
			)
		}
	}
	const isSm = useBreakpoint("sm")
	return (
		<CenteredBody>
			{isSm ? (
				<Row className="gap-betweenCards py-betweenChips">
					<Button
						size="flex"
						variant="secondary"
						asChild
						className=" group gap-betweenTexts bg-background-elevated dark:bg-background-subtle w-full py-5 rounded-md"
					>
						{isOwner && (
							<Link to="/withdraw">
								Retirar
								<ArrowUpLeft className="group-hover:animate-diagonal-bounce " />
							</Link>
						)}
					</Button>
					<Button
						size="flex"
						variant="secondary"
						asChild
						className="group gap-betweenTexts bg-background-elevated dark:bg-background-subtle w-full py-5 rounded-md"
					>
						<Link to="/charge">
							Recibir
							<ArrowDownRight className="group-hover:animate-diagonal-bounce " />
						</Link>
					</Button>
				</Row>
			) : (
				<BalanceSection showButton={false} />
			)}
			<div>
				<Row className="items-center pb-textToComponent justify-between">
					<SectionTitle
						title="Monedas"
						subtitle="Estas son las monedas en cuenta"
					/>
				</Row>

				{body}
			</div>
		</CenteredBody>
	)
}

export default WalletBody
