import * as amplitude from "@amplitude/analytics-browser"
import {
	getAnalytics,
	logEvent,
	setUserId,
	setUserProperties,
} from "@firebase/analytics"
import { createContext, useContext, useEffect, useState } from "react"
import logger from "../utils/logger"
import { useAuth } from "./AuthContext"

const AnalyticsContext = createContext<any>({})
export const useAnalytics = () => useContext(AnalyticsContext)

enum AnalyticsPlatform {
	Firebase = "firebase",
	Amplitude = "amplitude",
}

export enum DashboardCard {
	qr_crypto = "qr_crypto",
	docs = "docs",
	qr_payment = "qr_payment",
	tiendanube = "tiendanube",
	support = "support",
}

export enum PaymentChannel {
	api = "api",
	dashboard = "dashboard",
	qr_crypto = "qr_crypto",
	tiendanube = "tiendanube",
}

export enum CallToAction {
	start_now = "start_now",
	faq = "faq",
	contact_us = "contact_us",
	docs = "docs",
	pricing = "pricing",
	login = "login",
}

enum AnalyticsEvent {
	signup = "signup",
	email_verification = "email_verification",
	team_created = "team_created",
	qr_registered = "qr_registered",
	button_clicked = "button_clicked",
	login = "login",
	logout = "logout",
	dasboard_card_clicked = "dasboard_card_clicked",
	qr_payment_cancelled = "qr_payment_cancelled",
	error_creating_payment = "error_creating_payment",
	toggle_payment_notification = "toggle_payment_notification",
	toggle_fiat_payments = "toggle_fiat_payments",
	withdrawal = "withdrawal",
	payment_created = "payment_created",
	currency_selected = "currency_selected",
	wallet_selected = "wallet_selected",
	qr_payment_created = "qr_payment_created",
	gas_sent = "gas_sent",
	page_viewed = "page_viewed",
}

const AnalyticsProvider = ({ children }: any) => {
	const [firebaseAnalytics, setFirebaseAnalytics] = useState<any>(null)
	const [amplitudeAnalytics, setAmplitudeAnalytics] = useState<any>(null)

	const { user } = useAuth()

	useEffect(() => {
		//Firebase
		const initAnalytics = async () => {
			const _firebaseAnalytics = getAnalytics()
			setFirebaseAnalytics(_firebaseAnalytics)
			if (user?.id) {
				setUserId(_firebaseAnalytics, user?.team_id ?? user?.user_id)
			}

			//Amplitude
			const apiKey = process.env.REACT_APP_AMPLITUDE_API_KEY
			if (apiKey) {
				await amplitude.init(apiKey, user?.team_id ?? user?.user_id, {
					defaultTracking: {
						attribution: true,
						pageViews: false,
						sessions: false,
						formInteractions: false,
						fileDownloads: false,
					},
					trackingOptions: {
						platform: true,
					},
				})
				setAmplitudeAnalytics(amplitude)
			}
		}
		initAnalytics()
		// }
	}, [user])

	const logAnalyticsEvent = (
		event_name: AnalyticsEvent,
		event_props: any,
		platform?: AnalyticsPlatform
	) => {
		const logToFirebase =
			(platform === AnalyticsPlatform.Firebase || !platform) &&
			firebaseAnalytics
		const logToAmplitude =
			(platform === AnalyticsPlatform.Amplitude || !platform) &&
			amplitudeAnalytics

		try {
			if (logToFirebase) {
				logEvent(firebaseAnalytics, event_name.toString(), event_props)
			}
			if (logToAmplitude) {
				amplitudeAnalytics.track(event_name.toString(), event_props)
			}
		} catch (e) {
			logger.info("error submitting analytics")
			logger.info(e)
		}
	}

	const setAnalyticsUserProperties = (platform?: AnalyticsPlatform) => {
		const user_props = {
			email: user?.email,
			id: user?.team_id ?? user?.user_id,
			user_role: user?.roles,
			is_email_verified: user?.emailVerified,
			team_id: user?.team_id,
			registration_method: user?.provider,
		}

		const logToFirebase =
			(platform === AnalyticsPlatform.Firebase || !platform) &&
			firebaseAnalytics
		const logToAmplitude =
			(platform === AnalyticsPlatform.Amplitude || !platform) &&
			amplitudeAnalytics
		if (logToFirebase) {
			setUserProperties(firebaseAnalytics, user_props)
		}
		if (logToAmplitude) {
			const identifyEvent = new amplitudeAnalytics.Identify()
			Object.entries(user_props).forEach(([key, value]) => {
				identifyEvent.set(key, value)
			})

			amplitudeAnalytics.identify(identifyEvent)
		}
	}

	const setAnalyticsUserId = (
		user_id?: string,
		platform?: AnalyticsPlatform
	) => {
		const logToFirebase =
			(platform === AnalyticsPlatform.Firebase || !platform) &&
			firebaseAnalytics
		const logToAmplitude =
			(platform === AnalyticsPlatform.Amplitude || !platform) &&
			amplitudeAnalytics

		if (logToFirebase) {
			setUserId(firebaseAnalytics, user_id ?? null)
		}
		if (logToAmplitude) {
			amplitudeAnalytics.setUserId(user_id)
		}
	}

	const logSignUp = (
		eventParams: {
			email: string
			user_id: string
			user_role: string
			is_email_verified: boolean
			team_id?: string
			register_method: string
			signed_up_at: string
		},
		platform?: AnalyticsPlatform
	) => {
		logAnalyticsEvent(AnalyticsEvent.signup, eventParams, platform)
		setAnalyticsUserId(eventParams.team_id ?? eventParams.user_id, platform)
		// setUser properties
		setAnalyticsUserProperties(platform)
	}

	// Se loguea en el back
	const logEmailVerification = (
		eventParams: {
			email: string
			user_id: string
			team_id?: string
			email_verified_at: string
		},
		platform?: AnalyticsPlatform
	) => {
		logger.info("logEmailVerification")
		logAnalyticsEvent(AnalyticsEvent.email_verification, eventParams, platform)
	}

	const logTeamCreated = (
		eventParams: {
			user_id: string
			team_id: string
			team_email: string
			team_created_at: string
		},
		platform?: AnalyticsPlatform
	) => {
		logger.info("logTeamCreated")
		logAnalyticsEvent(AnalyticsEvent.team_created, eventParams, platform)
		// Increase team count for user
		incEventCount(AnalyticsEvent.team_created)
	}

	const logFiatPaymentsToggled = (
		eventParams: {
			user_id: string
			is_enabled: boolean
			toggled_at: string
		},
		platform?: AnalyticsPlatform
	) => {
		logger.info("logFiatPaymentsToggled")
		logAnalyticsEvent(
			AnalyticsEvent.toggle_fiat_payments,
			eventParams,
			platform
		)
	}

	const logLogin = (
		eventParams: {
			login_method: string
			user_id: string
			team_id?: string
			login_at: string
		},
		platform?: AnalyticsPlatform
	) => {
		logger.info("logLogin")
		logAnalyticsEvent(AnalyticsEvent.login, eventParams, platform)
		setAnalyticsUserId(eventParams.team_id ?? eventParams.user_id, platform)
		setAnalyticsUserProperties(platform)
	}

	const logSignOut = (
		eventParams: {
			logout_platform: string
			logged_out_at: string
			user_id: string
		},
		platform?: AnalyticsPlatform
	) => {
		logger.info("logSignOut")
		logAnalyticsEvent(AnalyticsEvent.logout, eventParams, platform)
		setAnalyticsUserId(undefined, platform)
		amplitude.reset()
	}

	const logDashboardCardClick = (
		eventParams: {
			user_id: string
			team_id: string
			card_type: DashboardCard
			card_clicked_at: string
		},
		platform?: AnalyticsPlatform
	) => {
		logger.info("logDashboardCardClick")
		logAnalyticsEvent(
			AnalyticsEvent.dasboard_card_clicked,
			eventParams,
			platform
		)
	}

	const logButtonClicked = (
		eventParams: {
			action: CallToAction
			button_clicked_at: string
		},
		platform?: AnalyticsPlatform
	) => {
		logger.info("logButtonClicked")
		logAnalyticsEvent(AnalyticsEvent.button_clicked, eventParams, platform)
	}

	const logQrRegistered = (
		eventParams: {
			user_id: string
			team_id?: string
			qr_registered_at: string
		},
		platform?: AnalyticsPlatform
	) => {
		logger.info("logQrRegistered")
		logAnalyticsEvent(AnalyticsEvent.qr_registered, eventParams, platform)
		//increase qr count for user
	}

	const logQrPaymentCancelled = (
		eventParams: {
			user_id: string
			team_id?: string
			qr_id: string
			payment_id: string
			payment_cancelled_at: string
		},
		platform?: AnalyticsPlatform
	) => {
		logger.info("logQrPaymentCancelled")
		logAnalyticsEvent(
			AnalyticsEvent.qr_payment_cancelled,
			eventParams,
			platform
		)
	}

	const logWithdrawal = (
		eventParams: {
			user_id: string
			currency: string
			network: string
			proportional_amount: number
			withdrawal_at: string
		},
		platform?: AnalyticsPlatform
	) => {
		logger.info("logWithdrawal")
		logAnalyticsEvent(AnalyticsEvent.withdrawal, eventParams, platform)
	}

	const logTogglePaymentNotification = (
		eventParams: {
			user_id: string
			is_enabled: boolean
			toggled_at: string
		},
		platform?: AnalyticsPlatform
	) => {
		logger.info("logTogglePaymentNotification")
		logAnalyticsEvent(
			AnalyticsEvent.toggle_payment_notification,
			eventParams,
			platform
		)
	}

	const logCreatePayment = (
		eventParams: {
			user_id: string
			team_id?: string
			price_amount: number
			price_currency: string
			creation_channel: PaymentChannel
			payment_created_at: string
			qr_id?: string
		},
		platform?: AnalyticsPlatform
	) => {
		logger.info("logCreatePayment")
		logAnalyticsEvent(AnalyticsEvent.payment_created, eventParams, platform)
		if (eventParams.creation_channel === PaymentChannel.qr_crypto) {
			logAnalyticsEvent(
				AnalyticsEvent.qr_payment_created,
				{
					user_id: eventParams.user_id,
					team_id: eventParams.team_id,
					qr_id: eventParams.qr_id,
					payment_created_at: eventParams.payment_created_at,
				},
				platform
			)
		}

		// Add 1 to payment count
		incEventCount(AnalyticsEvent.payment_created)
	}

	const incEventCount = (event: AnalyticsEvent) => {
		logger.info("incPaymentCount")
		if (amplitudeAnalytics) {
			const identifyEvent = new amplitudeAnalytics.Identify()
			switch (event) {
				case AnalyticsEvent.payment_created:
					identifyEvent.add("payment_count", 1)
					break
				case AnalyticsEvent.error_creating_payment:
					identifyEvent.add("error_count", 1)
					break
				case AnalyticsEvent.team_created:
					identifyEvent.add("team_count", 1)
					break
				default:
					break
			}
			amplitudeAnalytics.identify(identifyEvent)
		}

		if (firebaseAnalytics) {
			// No encontré forma de incrementar un campo en firebase user properties aún.
		}
	}

	const logErrorCreatePayment = (
		eventParams: {
			user_id: string
			team_id?: string
			creation_channel: PaymentChannel
			error_message: string
			error_at: string
		},
		platform?: AnalyticsPlatform
	) => {
		logger.info("logErrorCreatePayment")
		logAnalyticsEvent(
			AnalyticsEvent.error_creating_payment,
			eventParams,
			platform
		)
		incEventCount(AnalyticsEvent.error_creating_payment)
	}

	const logCurrencySelected = (
		eventParams: {
			currency: string
			payment_id: string
			network: string
			currency_selected_at: string
		},
		platform?: AnalyticsPlatform
	) => {
		logger.info("logCurrencySelected")
		logAnalyticsEvent(AnalyticsEvent.currency_selected, eventParams, platform)
	}

	const logWalletSelected = (
		eventParams: {
			wallet: string
			payment_id: string
			network: string
			currency: string
			wallet_selected_at: string
		},
		platform?: AnalyticsPlatform
	) => {
		logger.info("logWalletSelected")
		logAnalyticsEvent(AnalyticsEvent.wallet_selected, eventParams, platform)
	}

	return (
		<AnalyticsContext.Provider
			value={{
				logSignUp,
				logEmailVerification,
				logTeamCreated,
				logLogin,
				logSignOut,
				logDashboardCardClick,
				logButtonClicked,
				logQrRegistered,
				logQrPaymentCancelled,
				logWithdrawal,
				logTogglePaymentNotification,
				logCreatePayment,
				logErrorCreatePayment,
				logCurrencySelected,
				logWalletSelected,
				logFiatPaymentsToggled,
			}}
		>
			{children}
		</AnalyticsContext.Provider>
	)
}

export default AnalyticsProvider
