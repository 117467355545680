import SettingsSections from "../../components/app/settings/SettingsSection"
import { Button } from "../../components/ui/button"
import CenteredBody from "../../components/ui/layouts/platform/body/CenteredBody"
import { useAuth } from "../../context/AuthContext"
import { settingsSections } from "./items"

const SettingsBody = () => {
	const { hasPrivilege } = useAuth()

	return (
		<CenteredBody>
			<div className="flex flex-col gap-y-betweenSections">
				{settingsSections
					.filter((sec) =>
						hasPrivilege(sec.minimumPrivateRole, sec.minimumPublicRole)
					)
					.map((section) => (
						<SettingsSections
							key={section.title}
							title={section.title}
							description={section.description}
							subItems={section.subItems.filter((subItem) =>
								hasPrivilege(
									subItem.minimumPrivateRole,
									subItem.minimumPublicRole
								)
							)}
						/>
					))}
			</div>
		</CenteredBody>
	)
}

export default SettingsBody
