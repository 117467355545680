import { formatEther, parseEther } from "ethers"
import { Loader2 } from "lucide-react"
import { useMemo } from "react"
import { formatPriceAmount } from "../../utils/numbers"
import { Card } from "../ui/card"
import { Heading4, Heading5, Heading6 } from "../ui/texts/TextStyle"
import { Section } from "../ui/section"
import BodyItem from "../modals/payment-modal/body/BodyItem"
import Row from "../ui/layouts/row"
import { Separator } from "../ui/separator"
import { SectionHeader } from "../ui/section-header"
import Col from "../ui/layouts/column"

export interface WithdrawQuote {
	amount_rounded: string
	total_amount: string
	fee: {
		amount_rounded: string
		currency: string
	}
}

export interface PriceData {
	amount: string
	currency: string
	availableBalanceCurrency: string
}

type SendMoneySummaryCardProps = {
	quote?: WithdrawQuote
	prices: any
	priceData: PriceData
	loading?: boolean
}

function WithdrawSummaryCryptoCard({
	quote,
	prices,
	priceData,
	loading = false,
}: SendMoneySummaryCardProps) {
	const quotePrices = useMemo(() => {
		if (!quote || !prices) return
		const getPrice = (currency: string) => {
			return prices.find((p: any) => p.currency === currency)
		}
		const inputAmount = Number(formatEther(quote?.amount_rounded))

		return {
			inputAmount: inputAmount,
			inputAmountInPreferredCurrency: priceData.amount, //parseEther(priceData.amount).div(getPrice("nuARS")),
			inputCurrency: priceData.availableBalanceCurrency,
			preferredCurrency: priceData.currency,
			feeAmount:
				quote?.fee?.amount_rounded &&
				Number(formatEther(quote?.fee?.amount_rounded)).toFixed(5),
			feeCurrency: quote?.fee?.currency,
			feeAmountInPreferredCurrency: Number(
				calculateConversionPrice(
					{
						amount: formatEther(quote?.fee?.amount_rounded),
						currency: quote?.fee?.currency,
					},
					priceData.currency
				)
			).toFixed(5),
			totalAmount: quote
				? Number(formatEther(quote?.total_amount)).toFixed(5)
				: 0,
			totalAmountInPreferredCurrency: Number(
				calculateConversionPrice(
					{
						amount: formatEther(quote?.total_amount),
						currency: quote?.fee?.currency,
					},
					priceData.currency
				)
			)
				.toFixed(5)
				.replace(/(?:\.0+|(\.\d+?)0+)$/, "$1"),
			shouldDisplayPreferredCurrency:
				priceData.currency !== priceData.availableBalanceCurrency,
		}
	}, [quote, prices, priceData])

	function calculateConversionPrice(
		originalPrice: { amount: string; currency: string },
		destinationCurrency: any
	): string {
		if (!prices || !originalPrice || !destinationCurrency) {
			throw new Error("Missing data to calculate conversion price")
		}
		if (originalPrice.currency === destinationCurrency) {
			return originalPrice.amount
		}
		// Get original price currency conversion rate
		const conversionRateOrigin = prices.find(
			(elem: any) => elem.currency === originalPrice.currency
		)?.amount
		const conversionRateDestination = prices.find(
			(elem: any) => elem.currency === destinationCurrency
		)?.amount
		// Regla de 3 simple
		// destPriceConversionRate /(orignalPriceConversionRateAmount * originalPrice.amount
		const amountInDestinationCurrency =
			(BigInt(conversionRateDestination) * parseEther(originalPrice.amount)) /
			BigInt(conversionRateOrigin)

		//cant convert from number with decimals to bigint so we convert to string

		return formatEther(amountInDestinationCurrency.toString())
	}
	return (
		<Col className="gap-betweenCards p-md bg-background-subtle rounded-md">
			<Heading5 className="font-semibold">Resumen</Heading5>

			<Section>
				<BodyItem
					name="Monto ingresado"
					value={
						loading ? (
							<Loader2 className="animate-spin" />
						) : (
							<Row className="gap-betweenTexts">
								{quotePrices?.inputAmount && quotePrices?.inputCurrency && (
									<>
										$
										{formatPriceAmount(
											quotePrices?.inputAmount,
											undefined,
											quotePrices?.inputCurrency
										)}
										{quotePrices?.inputCurrency}
										{quotePrices?.shouldDisplayPreferredCurrency && (
											<div className="flex justify-end text-text-muted">
												( $
												{formatPriceAmount(
													quotePrices?.inputAmountInPreferredCurrency,
													undefined,
													quotePrices?.preferredCurrency
												)}
												{quotePrices?.preferredCurrency})
											</div>
										)}
									</>
								)}
							</Row>
						)
					}
				/>
				<BodyItem
					name="Costo de Red"
					value={
						loading ? (
							<Loader2 className=" animate-spin" />
						) : (
							quotePrices?.feeAmount &&
							quote?.fee?.currency && (
								<Row className="gap-betweenTexts">
									+ ${quotePrices?.feeAmount}
									{quote?.fee?.currency}
									{quotePrices?.shouldDisplayPreferredCurrency && (
										<div className="flex justify-end text-text-muted">
											( $
											{formatPriceAmount(
												quotePrices?.feeAmountInPreferredCurrency
											)}
											{quotePrices?.preferredCurrency})
										</div>
									)}
								</Row>
							)
						)
					}
				/>
				<Separator className="bg-border" />

				<BodyItem
					name="Total a retirar"
					value={
						loading ? (
							<Loader2 className=" animate-spin" />
						) : (
							<Row className="gap-betweenTexts">
								$
								{formatPriceAmount(
									quotePrices?.totalAmount,
									undefined,
									quotePrices?.inputCurrency
								)}
								{quotePrices?.inputCurrency}
								{quotePrices?.shouldDisplayPreferredCurrency && (
									<div className="flex justify-end text-text-muted">
										( $
										{formatPriceAmount(
											quotePrices?.totalAmountInPreferredCurrency
										)}
										{quotePrices?.preferredCurrency})
									</div>
								)}
							</Row>
						)
					}
				/>
			</Section>
		</Col>
	)
}

export default WithdrawSummaryCryptoCard
