import { apiPrivate } from "./interceptors"

export async function getAddressDetails(address: string) {
	const res = await apiPrivate.get(`/cvu/${address}/bank-info`)
	return res.data.data
}

export async function getUserCashoutCvus({ user_id }: { user_id: string }) {
	const res = await apiPrivate.get(`/cvu/?user_id=${user_id}&type=address_book`)
	return res.data.data.address_book
}
