import { formatEther } from "ethers"
import { WithdrawWizardData } from "../../../pages/withdraw/withdraw-wizard-types"
import { formatPriceAmount } from "../../../utils/numbers"
import { Sheet } from "../../ui/sheet"
import CvuSheetContent from "./CvuSheetContent"
import CryptoSheetContent from "./CryptoSheetContent"

const WithdrawConfirmationModal = ({
	open,
	onClose,
	handleConfirm,
	wizardData,
}: {
	open: boolean
	onClose: () => void
	handleConfirm: () => void
	wizardData: WithdrawWizardData
}) => {
	return (
		<Sheet
			open={open}
			onOpenChange={(open) => {
				if (!open) onClose()
			}}
		>
			{wizardData.data.quote && (
				<CryptoSheetContent
					data={wizardData.data}
					handleConfirm={handleConfirm}
				/>
			)}

			{wizardData.data.withdrawType !== "crypto" &&
				wizardData.data.currency &&
				wizardData.data.amount && (
					<CvuSheetContent
						data={wizardData.data}
						handleConfirm={handleConfirm}
					/>
				)}
		</Sheet>
	)
}

export function formatAmountAndCurrency(
	amount: string,
	currency: string,
	bigNumber: boolean
) {
	if (bigNumber) {
		return `${formatPriceAmount(
			formatEther(amount),
			undefined,
			currency
		)} ${currency}`
	}
	return `${formatPriceAmount(amount)} ${currency}`
}

export default WithdrawConfirmationModal
