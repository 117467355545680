import {
	getPaymentAddress,
	getPaymentComission,
	getPaymentCommissionArs,
	getPaymentCreditedAmount,
	getPaymentCreditedAmountArs,
	getPaymentCurrency,
	getPaymentExpectedAmount,
	getPaymentNetwork,
	getPaymentReceivedAmount,
	getPaymentReceivedAmountArs,
	getPaymentReceivedAmountInRequestedCurrency,
	getWithdrawnAmount,
	getWithdrawnAmountArs,
} from "../../../lib/utils"
import { formatDate } from "../../../utils/format"
import { TableTransaction } from "./columns"

export async function getPayments({
	payments,
	transactions,
}: {
	payments: any
	transactions: any
}): Promise<TableTransaction[]> {
	if (transactions && payments) {
		var txList = transactions ?? []
		var pendingPayments: any[] = []
		var otherPayments: any[] = []
		payments?.forEach((payment: any) => {
			if (payment.payment_status === "EXPIRED") {
				return
			}
			if (payment.payment_status === "PENDING") {
				pendingPayments.push(payment)
			} else {
				otherPayments.push(payment)
			}
		})
		const outboundTransactions = txList.filter(
			(transaction: any) =>
				transaction.transactionType === "OUTBOUND" ||
				transaction.type === "OUTBOUND"
		)

		//merge other payments and outbound transactions sorted by date
		otherPayments = otherPayments.concat(outboundTransactions).sort((a, b) => {
			return (
				new Date(b.creation_timestamp).getTime() -
				new Date(a.creation_timestamp).getTime()
			)
		})

		return [...pendingPayments, ...otherPayments].map(mapToTableTransaction)
	}
	return []
}
function isTransferPayment(payment: any) {
	return (
		(payment.payment_options && payment.payment_options.includes("transfer")) ||
		(payment.currency &&
			payment.network &&
			payment.currency === "ARS" &&
			payment.network === "POLLUX")
	)
}

export function mapToTableSubTransaction(transaction: any): any {
	return {
		network: transaction.network,
		currency: transaction.currency,
		amount: transaction.amount,
		amountReadable: transaction.amountReadable,
		transactionHash: transaction.transaction_hash,
		transactionId: transaction.transaction_id,
		senderAddress: transaction.sender_address,
		date: formatDate(new Date(transaction.creation_timestamp)),
		time: new Date(transaction.creation_timestamp).toLocaleTimeString("es-AR", {
			hour: "2-digit",
			minute: "2-digit",
		}),
	}
}

export function mapToTableSubTransactionArs(transaction: any): any {
	return {
		network: transaction.network,
		currency: transaction.currency,
		amount: transaction.amount,
		amountReadable: transaction.amountReadable,
		transactionId: transaction.transaction_id,
		senderAddress: transaction.sender_address,
		date: formatDate(new Date(transaction.creation_timestamp)),
		time: new Date(transaction.creation_timestamp).toLocaleTimeString("es-AR", {
			hour: "2-digit",
			minute: "2-digit",
		}),
	}
}

export function mapToTableTransactionArs(payment: any): TableTransaction {
	try {
		const lastModifiedDateTime = new Date(payment.last_modified_timestamp)
		const lastModifiedTime = lastModifiedDateTime.toLocaleTimeString("es-AR", {
			hour: "2-digit",
			minute: "2-digit",
		})
		const lastModifiedDate = formatDate(lastModifiedDateTime)
		if (payment.payment_status) {
			const received = getPaymentReceivedAmountArs(payment)
			const receivedInRequestedCurrency = getPaymentReceivedAmountArs(payment)
			const comission = getPaymentCommissionArs(payment)
			const credited = getPaymentCreditedAmountArs(payment)
			const creditedInRequestedCurrency = getPaymentCreditedAmountArs(payment)
			return {
				id: payment.id,
				// lastModifiedDateTime: new Date(payment.last_modified_timestamp),
				lastModifiedDateTime: {
					date: lastModifiedDate,
					time: lastModifiedTime,
				},
				orderId:
					(
						payment.tiendanube?.order_id ?? payment.woocommerce?.order_id
					)?.toString() ?? "-",
				currency: getPaymentCurrency(payment) ?? "-",
				expected: payment.price,
				received: received,
				receivedInRequestedCurrency: receivedInRequestedCurrency,
				credited: credited,
				creditedInRequestedCurrency: creditedInRequestedCurrency,
				status: payment.payment_status ?? "-",
				price: payment.price,
				comission: comission,
				network: getPaymentNetwork(payment),
				address: getPaymentAddress(payment),
				transactions: payment.transactions?.map(mapToTableSubTransactionArs),
				tiendanube: payment.tiendanube,
				woocommerce: payment.woocommerce,
				paymentUrl: payment.payment_url,
			}
		} else {
			const withdrawnAmount = getWithdrawnAmountArs(payment)
			if (payment.amount) {
				return {
					id: payment.transaction_id ?? "-",
					// lastModifiedDateTime: new Date(payment.last_modified_timestamp),
					lastModifiedDateTime: {
						date: lastModifiedDate,
						time: lastModifiedTime,
					},
					orderId: "-",
					currency: payment.currency ?? "-",
					network: payment.network,
					address: payment.address,
					credited: withdrawnAmount,
					received: withdrawnAmount,
					expected: withdrawnAmount,
					status: payment.transaction_status?.toLowerCase() ?? "SENT",
					transactionHash: payment.transaction_hash,
				}
			} else {
				console.error(payment)
				throw new Error("Invalid transaction")
			}
		}
	} catch (err) {
		console.error("Error in mapToTableTransactionArs", err)
		return payment
	}
}
export function mapToTableTransaction(payment: any): TableTransaction {
	if (isTransferPayment(payment)) {
		return mapToTableTransactionArs(payment)
	} else {
		return mapToTableTransactionCrypto(payment)
	}
}

export function mapToTableTransactionCrypto(payment: any): TableTransaction {
	const lastModifiedDateTime = new Date(payment.last_modified_timestamp)
	const lastModifiedTime = lastModifiedDateTime.toLocaleTimeString("es-AR", {
		hour: "2-digit",
		minute: "2-digit",
	})
	const lastModifiedDate = formatDate(lastModifiedDateTime)
	if (payment.payment_status) {
		const quote = payment.quotes!.find(
			(q: any) =>
				q.currency === payment.transaction_fields?.currency &&
				q.network === payment.transaction_fields?.network
		)
		const received = getPaymentReceivedAmount(payment)
		const receivedInRequestedCurrency = quote
			? getPaymentReceivedAmountInRequestedCurrency(payment, quote)
			: undefined
		const comission = quote ? getPaymentComission(payment, quote) : undefined
		const credited = getPaymentCreditedAmount(received, comission?.comission)
		const creditedInRequestedCurrency = getPaymentCreditedAmount(
			receivedInRequestedCurrency,
			comission?.comissionInRequestedCurrency
		)
		return {
			id: payment.id,
			// lastModifiedDateTime: new Date(payment.last_modified_timestamp),
			lastModifiedDateTime: {
				date: lastModifiedDate,
				time: lastModifiedTime,
			},
			orderId:
				(
					payment.tiendanube?.order_id ??
					payment.woocommerce?.order_id ??
					payment.customer_email
				)?.toString() ?? "-",
			currency: getPaymentCurrency(payment) ?? "-",
			expected: getPaymentExpectedAmount(payment, quote),
			received: received,
			receivedInRequestedCurrency: receivedInRequestedCurrency,
			credited: credited,
			creditedInRequestedCurrency: creditedInRequestedCurrency,
			status: payment.payment_status ?? "-",
			price: payment.price,
			comission: comission,
			network: getPaymentNetwork(payment),
			address: getPaymentAddress(payment),
			transactions: payment.transactions?.map(mapToTableSubTransaction),
			transactionHash: payment.transaction_fields?.transaction_hash,
			tiendanube: payment.tiendanube,
			woocommerce: payment.woocommerce,
			paymentUrl: payment.payment_url,
		}
	} else {
		const withdrawnAmount = getWithdrawnAmount(payment)
		if (payment.amount) {
			return {
				id: payment.transaction_id ?? "-",
				// lastModifiedDateTime: new Date(payment.last_modified_timestamp),
				lastModifiedDateTime: {
					date: lastModifiedDate,
					time: lastModifiedTime,
				},
				orderId: "-",
				currency: payment.currency ?? "-",
				network: payment.network,
				address: payment.address,
				credited: withdrawnAmount,
				received: withdrawnAmount,
				expected: withdrawnAmount,
				status: "SENT",
				transactionHash: payment.transaction_hash,
			}
		} else {
			console.error(payment)
			throw new Error("Invalid transaction")
		}
	}
}
