import { ReactNode } from "react"
import { Card, CardContent, CardHeader } from "../../card"
import TaloGreenLogo from "../../logos/TaloGreenLogo"
import Row from "../row"
import UserHeader from "../../menus/user-nav/UserHeader"
import { mobilePlatformHeader } from "../layout-sizes"
import { BackgroundGradientAnimation } from "../../background-gradient-animation"
import Col from "../column"
import { Heading3 } from "../../texts/TextStyle"
import { Badge } from "../../badge"
import { Button } from "../../button"

const MobilePlatformHeader = ({
	children,
}: {
	children: ReactNode | ReactNode[]
}) => {
	return (
		<Col className="w-full gap-betweenSections justify-center items-center rounded-b-md px-screenMobile pt-screenMobile -z-0 h-fit">
			<Row
				className={`${mobilePlatformHeader.navbarHeight.class} items-center justify-between w-full max-w-[696px] relative z-50`}
			>
				<TaloGreenLogo className={mobilePlatformHeader.logoWidth.class} />
				<UserHeader />
			</Row>
			<Row className="w-full items-center justify-between max-w-[696px]">
				<Heading3>Tablero</Heading3>
				{/* <Button
					size="sm"
					variant="outline"
					className="bg-background rounded-full"
				>
					Verificando solicitud ...
				</Button> */}
			</Row>
			<div className="z-50 w-full max-w-[696px]">{children}</div>
		</Col>
	)
}

export default MobilePlatformHeader
