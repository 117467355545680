import useBreakpoint from "../../../hooks/useBreakpoint"
import {
	Price,
	TableTransaction,
} from "../../tables/transactions-table/columns"
import CenteredBody from "../../ui/layouts/platform/body/CenteredBody"
import {
	Sheet,
	SheetClose,
	SheetContent,
	SheetDescription,
	SheetHeader,
	SheetTitle,
} from "../../ui/sheet"
import SlidingCard from "../../ui/sliding-card"
import SectionTitle from "../../ui/titles/SectionTitle"
import OutboundTransactionModalBody from "./body/OutboundTransactionModalBody"
import PendingPaymentModalBody from "./body/PendingPaymentModalBody"
import SuccessPaymentModalBody from "./body/SuccessPaymentModalBody"
import PaymentStatusRow from "./header/PaymentStatusRow"

export interface PaymentComission {
	comission?: Price
	comissionInRequestedCurrency?: Price
}

interface PaymentModalProps {
	open: boolean
	onClose: () => void
	payment: TableTransaction
}
const PaymentModal = ({ open, onClose, payment }: PaymentModalProps) => {
	return (
		<SlidingCard
			// header={
			// 	<SectionTitle
			// 		title="Transacción"
			// 		subtitle="Accedé a la información más detallada"
			// 	/>
			// }
			open={open}
			onOpenChange={(open) => {
				if (!open) onClose()
			}}
			side={"right"}
		>
			<div>
				<PaymentStatusRow status={payment.status} />
				{renderModalBody(payment)}
			</div>
		</SlidingCard>
	)
}

function renderModalBody(payment: TableTransaction) {
	switch (payment.status) {
		case "SUCCESS":
		case "OVERPAID":
		case "UNDERPAID":
			return <SuccessPaymentModalBody payment={payment} />
		case "SENT":
			return <OutboundTransactionModalBody payment={payment} />
		case "PENDING":
			return <PendingPaymentModalBody payment={payment} />
	}
}

export default PaymentModal
