import { QRCodeSVG } from "qrcode.react"
import { TableTransaction } from "../../../tables/transactions-table/columns"
import { Input } from "../../../ui/inputs/input"
import BodyBlock from "./BodyBlock"

const PendingPaymentModalBody = ({
	payment,
}: {
	payment: TableTransaction
}) => {
	return (
		<BodyBlock title="Escaneá el código para acceder al link de pago:">
			{payment.paymentUrl && (
				<div className="flex flex-col items-center mx-auto h-6/10 w-full">
					<span className="w-full flex justify-center py-betweenComponents">
						<QRCodeSVG
							level="M"
							value={payment.paymentUrl}
							className="h-4/5 max-w-[400px] max-h-[400px] w-full"
						/>
					</span>
					{/* <CopyButton textToCopy={payment.paymentUrl} /> */}
					<div className="w-full flex flex-row py-betweenComponents items-center">
						<Input type="copy" value={payment.paymentUrl} />
						{/* <div className="flex-grow flex flex-row items-center border border-dashed border-border-input rounded-sm p-betweenTexts max-w-[calc(100%-24px)]">
							<Link size={24} />
							<div className="flex-grow  overflow-hidden pl-betweenTexts">
								<Heading6 className="overflow-ellipsis overflow-hidden font-normal ">
									{payment.paymentUrl}
								</Heading6>
							</div>
						</div>
						<CopyButton textToCopy={payment.paymentUrl} /> */}
					</div>
				</div>
			)}
		</BodyBlock>
	)
}

export default PendingPaymentModalBody
