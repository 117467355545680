import { TableTransaction } from "../../../tables/transactions-table/columns"
import { CurrencyImage } from "../../../tables/transactions-table/columns/currencyImageCell"
import { Heading2, Heading6 } from "../../../ui/texts/TextStyle"
import BodyBlock from "./BodyBlock"
import BodyItem from "./BodyItem"
import DateInfo from "./DateInfo"

const OutboundTransactionModalBody = ({
	payment,
}: {
	payment: TableTransaction
}) => {
	return (
		<BodyBlock title="Detalles">
			<DateInfo {...payment.lastModifiedDateTime} />
			{payment.network && <BodyItem name="Red" value={payment.network} />}
			{payment.address && (
				<BodyItem
					name="Dirección de envío"
					value={payment.address}
					overflow={true}
					copy={true}
				/>
			)}
			<div className="flex justify-between items-center py-betweenComponents">
				<Heading6 className="font-medium text-text-muted">Enviado</Heading6>
				{payment.credited && (
					<div className="flex-col  mb-2 whitespace-nowrap ">
						<div className="flex justify-end">
							<CurrencyImage currency={payment.credited.currency} />

							<Heading2 className="ml-betweenTexts font-medium whitespace-nowrap">
								{payment.credited.amount}
							</Heading2>

							<Heading2 className="ml-betweenTexts font-light">
								{payment.credited.currency}
							</Heading2>
						</div>
					</div>
				)}
			</div>
		</BodyBlock>
	)
}

export default OutboundTransactionModalBody
