import { useAuth } from "../../../../context/AuthContext"
import { getFormattedStoreUrl } from "../../../../lib/utils"
import { TableTransaction } from "../../../tables/transactions-table/columns"
import SucessPaymentSummary from "./sections/SuccessPaymentSummary"
import SuccessPaymentTransactionDetails from "./sections/SuccessPaymentTransactionDetails"
import TiendanubePaymentDetails from "./sections/TiendanubePaymentDetails"
import WoocommercePaymentDetails from "./sections/WoocommercePaymentDetails"

const SuccessPaymentModalBody = ({
	payment,
}: {
	payment: TableTransaction
}) => {
	const { stores } = useAuth()
	const tiendanubeStore = stores.find(
		(store: any) => store.store_id === payment.tiendanube?.store_id
	)
	const woocommerceStore = stores.find(
		(store: any) => store.store_id === payment.woocommerce?.store_id
	)
	return (
		<div className="grid grid-cols-1 items-center">
			<SucessPaymentSummary payment={payment} />
			<SuccessPaymentTransactionDetails payment={payment} />
			{payment.tiendanube && (
				<TiendanubePaymentDetails
					info={payment.tiendanube}
					store_url={getFormattedStoreUrl(tiendanubeStore.store_url)}
				/>
			)}
			{payment.woocommerce && (
				<WoocommercePaymentDetails
					info={payment.woocommerce}
					store_url={getFormattedStoreUrl(woocommerceStore.store_url)}
				/>
			)}
		</div>
	)
}

export default SuccessPaymentModalBody
