import { Info } from "lucide-react"
import { WithdrawData } from "../../../pages/withdraw/withdraw-wizard-types"
import { Alert } from "../../ui/alert"
import Col from "../../ui/layouts/column"
import Column from "../../ui/layouts/column"
import Row from "../../ui/layouts/row"
import { SectionHeader } from "../../ui/section-header"
import { Separator } from "../../ui/separator"
import {
	SheetContent,
	SheetDescription,
	SheetFooter,
	SheetHeader,
	SheetTitle,
} from "../../ui/sheet"
import { Heading2, Heading6 } from "../../ui/texts/TextStyle"
import BodyItem from "../payment-modal/body/BodyItem"
import { formatAmountAndCurrency } from "./WithdrawConfirmationModal"
import { Button } from "../../ui/button"

interface CryptoSheetContentProps {
	data: WithdrawData
	handleConfirm: () => void
}

export default function CryptoSheetContent({
	data,
	handleConfirm,
}: CryptoSheetContentProps) {
	return (
		<SheetContent side="right">
			<div className="grid grid-cols-1 items-center">
				<SheetHeader className="py-betweenComponents">
					<SheetTitle className="pb-betweenSections">
						<SectionHeader title="Confirmá tu envío" />
					</SheetTitle>

					<SheetDescription>
						<Column>
							<Heading6 className="text-text-muted font-medium">
								Recibirás
							</Heading6>
							<Row className="gap-betweenChips">
								<img
									src={`/currencies/${data?.currency?.toLowerCase()}-icon.svg`}
									alt=""
								/>
								<Heading2 className="font-normal text-text">
									{formatAmountAndCurrency(
										data.quote.amount,
										data.quote.currency,
										true
									)}
								</Heading2>
							</Row>
						</Column>
					</SheetDescription>
				</SheetHeader>
				<Separator orientation="horizontal" />
				<Col>
					<BodyItem
						name="Direción de destino"
						value={data.address ?? ""}
						overflow
					/>
					<BodyItem name="Red" value={data.network ?? ""} />
					<BodyItem
						name="Costo de la red"
						value={formatAmountAndCurrency(
							data.quote.fee.amount_rounded,
							data.quote.fee.currency,
							true
						)}
					/>
					<BodyItem
						name="Monto total"
						value={formatAmountAndCurrency(
							data.quote.total_amount,
							data.quote.currency,
							true
						)}
					/>

					<Alert
						icon={<Info />}
						title="Cuidado"
						variant="warning"
						description="Asegurate que tu billetera soporte la red seleccionada. De lo contrario perderás los fondos"
					/>
				</Col>
				<SheetFooter className="py-betweenSections">
					<Button
						size="full"
						onClick={handleConfirm}
						data-test="withdraw-confirmation-modal-confirm-button"
					>
						Confirmar
					</Button>
				</SheetFooter>
			</div>
		</SheetContent>
	)
}
