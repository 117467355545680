import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation } from "@tanstack/react-query"
import { Loader, MapPin } from "lucide-react"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import { Button } from "../../../components/ui/button"
import { Form } from "../../../components/ui/form"
import InputErrorMessage from "../../../components/ui/inputs/InputErrorMessage.tsx"
import { Input } from "../../../components/ui/inputs/input"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../../../components/ui/inputs/select"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import Row from "../../../components/ui/layouts/row.tsx"
import { SectionHeader } from "../../../components/ui/section-header"
import SlidingCard from "../../../components/ui/sliding-card.tsx"
import Spacer from "../../../components/ui/spacer.tsx"
import { useAuth } from "../../../context/AuthContext.tsx"
import { apiPrivate } from "../../../services/interceptors.ts"
import {
	BasicInfoForm,
	OnboardingPersonalData,
	OnboardingPersonalSteps,
	OnboardingPersonalWizardData,
	PersonalDocumentType,
} from "./onboard-personal-wizard-types.ts"
import { useGetKyc } from "./useGetKyc.tsx"

const schema = z.object({
	first_name: z
		.string()
		.min(1, "El nombre es requerido")
		.regex(/^[a-zA-Z\s]+$/, "Solo puede contener caracteres alfabéticos"),
	last_name: z
		.string()
		.min(1, "El apellido es requerido")
		.regex(/^[a-zA-Z\s]+$/, "Solo puede contener caracteres alfabéticos"),
	document_type: z.enum(["CUIT"], {
		required_error: "Se requiere ingresar el cuit",
	}),
	cuit: z.string().min(11, "Este documento no es válido"),

	phone: z
		.string()
		.regex(/^\d{8,}$/, "Este número de teléfono no es válido")
		.min(10, "Este número de teléfono no es válido"),

	address: z.string().min(1, "La dirección es requerida"),
})

export default function FirstForm({
	setWizardData,
	wizardData,
}: {
	setWizardData: (data: OnboardingPersonalWizardData) => void
	wizardData: OnboardingPersonalWizardData
}) {
	const { user } = useAuth()

	const [showConfirmation, setShowConfirmation] = useState(false)
	const navigate = useNavigate()
	const { refetch } = useGetKyc(user?.user_id)

	const form = useForm<z.infer<typeof schema>>({
		resolver: zodResolver(schema),
		defaultValues: {
			first_name: wizardData.data.first_name || "",
			last_name: wizardData.data.last_name || "",
			cuit: wizardData.data.cuit || "",
			document_type: wizardData.data.document_type || "CUIT",
			phone: wizardData.data.phone || "",
			address: wizardData.data.address || "",
		},
	})

	const mutation = useMutation({
		mutationFn: ({
			completeData,
			userId,
		}: {
			completeData: OnboardingPersonalData
			userId: string
		}) => {
			return apiPrivate.post(`/users/${userId}/kyc`, {
				...completeData,
			})
		},
		retry: 1,
	})

	async function onSubmit(data: BasicInfoForm) {
		//Si el parse sobre la data no es lo que espera el schema va a throwear un error
		const parsedData = schema.parse(data)

		setWizardData({
			...wizardData,
			data: { ...wizardData.data, ...parsedData },
		})

		console.log("SUBMITTED DATA", parsedData)
	}

	async function postForm(parsedData: BasicInfoForm) {
		//parsing once again just to be sure :)
		schema.parse(parsedData)

		if (!user || !user.user_id) {
			return
		}

		const completeData = {
			...parsedData,
			entity_type: wizardData.data.entity_type,
			uif_conditions: {
				obligated_subject: wizardData.data.uif_conditions.obligated_subject,
				exposed_person: wizardData.data.uif_conditions.exposed_person,
				fatca_subject: wizardData.data.uif_conditions.fatca_subject,
				ocde_subject: wizardData.data.uif_conditions.ocde_subject,
			},
		}

		await mutation
			.mutateAsync({
				completeData: completeData,
				userId: user.user_id as string,
			})
			.then(() => {
				setWizardData({
					...wizardData,
					data: { ...wizardData.data, ...parsedData },
					completionPercentage: 70,
				})
				refetch()
				navigate("/onboarding/payment-methods")
			})
			.catch((err) => {
				console.error(err)
			})
	}

	return (
		<CenteredBody>
			<SectionHeader
				title="Configurá tu cuenta"
				subtitle="Completá tus datos personales para comenzar a usar nuestra plataforma"
			/>

			<Form {...form}>
				<form className="grid grid-cols-1 gap-betweenFields">
					<div className="md:flex-row flex flex-col w-full gap-betweenFields">
						{/* First Name */}
						<div className="w-full">
							<Input
								{...form.register("first_name")}
								placeholder="Juan"
								label="Nombre"
								required
							/>

							{form.formState.errors.first_name && (
								<InputErrorMessage
									errorText={form.formState.errors.first_name?.message}
								/>
							)}
						</div>

						{/* Last Name */}
						<div className="w-full">
							<Input
								{...form.register("last_name")}
								label="Apellido"
								required
								placeholder="Perez"
								defaultValue={wizardData.data.last_name}
							/>
							{form.formState.errors.last_name && (
								<InputErrorMessage
									errorText={form.formState.errors.last_name.message}
								/>
							)}
						</div>
					</div>

					{/* Document Type and CUIT */}
					<div>
						<Input
							label="Documento"
							required
							placeholder="20432008768"
							type="leading"
							className="rounded-l-none border-l-0"
							{...form.register("cuit")}
							defaultValue={wizardData.data.cuit}
							leading={
								<Controller
									name="document_type"
									control={form.control}
									defaultValue={"CUIT" as PersonalDocumentType}
									render={({ field }) => (
										<Select
											onValueChange={field.onChange}
											value={field.value as PersonalDocumentType}
											defaultValue={"CUIT" as PersonalDocumentType}
										>
											<SelectTrigger
												defaultValue={"CUIT" as PersonalDocumentType}
												className="w-fit rounded-e-none border-e-0"
											>
												<SelectValue
													defaultValue={"CUIT" as PersonalDocumentType}
												>
													{"CUIT" as PersonalDocumentType}
												</SelectValue>
											</SelectTrigger>
											<SelectContent className="text-center justify-center">
												<SelectItem
													className="text-center justify-center"
													value={"CUIT" as PersonalDocumentType}
												>
													CUIT
												</SelectItem>
											</SelectContent>
										</Select>
									)}
								/>
							}
						/>
						{form.formState.errors.cuit && (
							<InputErrorMessage
								errorText={form.formState.errors.cuit.message}
							/>
						)}
					</div>

					{/* Phone Number */}
					<div>
						<Input
							label="Teléfono"
							required
							placeholder="11 6973 4982"
							type="leading"
							{...form.register("phone")}
							className="rounded-l-none border-l-0"
							leading={
								<div className="flex h-lg rounded-xs px-sm py-xs border border-border-input bg-background-subtle text-h5 text-text-muted font-medium ring-offset-background placeholder:text-muted-foreground cursor-not-allowed rounded-e-none">
									<span className="text-center flex items-center justify-center">
										{"+54"}
									</span>
								</div>
							}
						/>
						{form.formState.errors.phone && (
							<InputErrorMessage
								errorText={form.formState.errors.phone.message}
							/>
						)}
					</div>

					{/* Billing Address */}
					<div>
						<Input
							icon={<MapPin />}
							type="text"
							required
							placeholder="Libertad 2901, Florida, Vicente Lopez"
							label="Dirección de facturación "
							{...form.register("address")}
						/>
						{form.formState.errors.address && (
							<InputErrorMessage
								errorText={form.formState.errors.address.message}
							/>
						)}
					</div>

					<Spacer value="300px" axis={"vertical"} />

					<Button
						type="submit"
						onClick={async (e) => {
							await form
								.handleSubmit(onSubmit)(e)
								.then(() => {
									console.log("FORM STATE===", form)
									form.formState.isSubmitSuccessful && setShowConfirmation(true)
								})
						}}
					>
						Finalizar
					</Button>

					<SlidingCard
						side={"center"}
						open={showConfirmation}
						onOpenChange={setShowConfirmation}
					>
						<CenteredBody>
							<SectionHeader
								title="¿Estás seguro que los datos proporcionados son correctos?"
								subtitle="Asegurate de que los datos proporcionados sean correctos ya que no se podrán modificar una vez enviados. "
							/>
							<Spacer value="200px" axis={"vertical"} />
							<Row className="gap-betweenButtons">
								<Button
									size="full"
									onClick={() => {
										setWizardData({
											...wizardData,
											step: OnboardingPersonalSteps.SpecialAgreements,
										})
									}}
								>
									{" "}
									Editar datos
								</Button>
								<Button
									variant="outline"
									size="full"
									onClick={() => {
										postForm(form.getValues())
									}}
								>
									{mutation.isPending ? (
										<Loader className={"animate-spin"} />
									) : (
										"Finalizar"
									)}
								</Button>
							</Row>
							{mutation.isError ? (
								<InputErrorMessage
									errorText={
										"No se pudo completar la operación, por favor intenta nuevamente"
									}
								/>
							) : null}
						</CenteredBody>
					</SlidingCard>

					{mutation.isError && (
						<p className="text-text-error">
							No se pudo completar la operación, por favor intentá nuevamente
						</p>
					)}
				</form>
			</Form>
		</CenteredBody>
	)
}
