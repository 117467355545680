import { TableTransaction } from "../../../../tables/transactions-table/columns"
import Spacer from "../../../../ui/spacer"
import { Heading6 } from "../../../../ui/texts/TextStyle"
import BodyBlock from "../BodyBlock"
import BodyItem from "../BodyItem"
import DateInfo from "../DateInfo"
import TransactionsAccordion from "../TransactionsAccordion"

const SuccessPaymentTransactionDetails = ({
	payment,
}: {
	payment: TableTransaction
}) => {
	return (
		<BodyBlock title="Otros detalles">
			<DateInfo {...payment.lastModifiedDateTime} />
			<BodyItem
				name="Cant. de transacciones"
				value={`${payment.transactions?.length ?? 1}`}
			/>
			{payment.network && <BodyItem name="Red" value={payment.network} />}

			{payment.address && (
				<BodyItem
					name="Dirección de destino"
					value={payment.address}
					copy={true}
					overflow={true}
				/>
			)}
			{payment.transactionHash && (
				<BodyItem
					name="Hash de transacción"
					value={payment.transactionHash}
					copy={true}
					overflow={true}
				/>
			)}
			{payment.transactions && payment.transactions.length > 1 && (
				<div className="py-betweenTexts">
					<Heading6 className="font-medium text-text-muted pb-betweenTexts">
						Transacciones
					</Heading6>
					<Spacer axis="vertical" type="betweenTexts"></Spacer>
					<TransactionsAccordion transactions={payment.transactions} />
				</div>
			)}
		</BodyBlock>
	)
}

export default SuccessPaymentTransactionDetails
