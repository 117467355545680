import { getAddress } from "ethers"
import { useEffect, useState } from "react"
import { FiatAddressDetails } from "../../pages/withdraw/withdraw-wizard-types"
import { getAddressDetails } from "../../services/cvu"
import { WithdrawType } from "../../utils/withdrawCurrencies"
import Col from "../ui/layouts/column"
import CenteredBody from "../ui/layouts/platform/body/CenteredBody"
import { Option } from "../ui/options/option"
import { SectionHeader } from "../ui/section-header"
import { Sheet, SheetContent } from "../ui/sheet"
import { Skeleton } from "../ui/skeleton"
import FrequentAddressesStep from "./FrequentAddressesStep"
import NewAddressStep from "./NewAddressStep"
import { Section } from "../ui/section"

export interface SavedAddress {
	network: string
	currency: string
	alias: string
	address: string
	creation_timestamp: string
}

export interface SelectAddressStepProps {
	network?: string
	addresses?: SavedAddress[]
	onNewAddress: ({ address, alias }: { address: string; alias: string }) => void
	onConfirmAddress: (
		address?: string,
		fiatAddressDetails?: FiatAddressDetails
	) => void
	onAddressChange: (address: string) => void
	address?: string
	withdrawType: WithdrawType
}

export const validateAddressCrypto = (address?: string) => {
	if (!address) return false
	try {
		getAddress(address)
		return true
	} catch (e) {
		return false
	}
}

//TODO: la validacion de cvu deberia ser mas robusta y incluir alias tambien
export const validateAddressCvu = (address?: string) => {
	if (!address) return false
	if (address.length !== 22) return false
	return true
}

export const validateAddressPix = (address?: string) => {
	if (!address) return false
	if (address.length !== 20) return false
	return true
}

const NewAddressStepVariants: {
	[key in WithdrawType]: {
		title: string
		subtitle: string
		placeholder: string
		validateAddress: (address: string) => boolean
	}
} = {
	crypto: {
		title: "Retirar en criptomonedas",
		subtitle:
			"Ingresá la dirección de la billetera donde querés recibir el dinero",
		placeholder: "Dirección de destino",
		validateAddress: validateAddressCrypto,
	},
	transfer: {
		title: "Retirar en pesos",
		subtitle: "Ingresá la cuenta bancaria donde querés recibir el dinero",
		placeholder: "Alias o Numero de cuenta",
		validateAddress: validateAddressCvu,
	},
	pix: {
		title: "Retirar en USDT",
		subtitle:
			"Ingresá la dirección de la billetera donde querés recibir el dinero cobrado por PIX en USDT",
		placeholder: "Dirección de destino",
		validateAddress: validateAddressCrypto,
	},
}

const SelectAddressStep = ({
	network,
	addresses,
	onConfirmAddress,
	onAddressChange,
	onNewAddress,
	address,
	withdrawType,
}: SelectAddressStepProps) => {
	const [newAddress, setNewAddress] = useState(false)
	const [isSheetOpen, setIsSheetOpen] = useState(false)
	const [fiatAddressDetails, setFiatAddressDetails] =
		useState<FiatAddressDetails>({
			fullName: "",
			bank_number: "",
			cbu: "",
		})

	useEffect(() => {
		if (addresses && addresses.length === 0) {
			setNewAddress(true)
		} else {
			setNewAddress(false)
		}

		if (withdrawType === "transfer") {
			network = "POLLUX"
		}
		if (withdrawType === "pix") {
			network = "POLYGON"
		}
	}, [addresses])

	async function fetchAddressDetails(
		address: string
	): Promise<FiatAddressDetails> {
		const res = await getAddressDetails(address)
		//mockeo un response con los datos del cvu pedido
		return res
	}

	async function handleOnConfirmAddressModalProxy() {
		if (withdrawType !== "crypto" && address) {
			if (!isSheetOpen) {
				console.log("fetching address details")
				const response = await fetchAddressDetails(address)
				setFiatAddressDetails(response)
				setIsSheetOpen(true)
			}
		} else {
			onConfirmAddress(address)
		}
	}

	return (
		<CenteredBody>
			<Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
				{addresses ? (
					<>
						<SectionHeader
							title={NewAddressStepVariants[withdrawType as WithdrawType].title}
							subtitle={
								NewAddressStepVariants[withdrawType as WithdrawType].subtitle
							}
						/>
						{newAddress ? (
							<NewAddressStep
								onNewAddress={onNewAddress}
								onConfirmAddress={() => handleOnConfirmAddressModalProxy()}
								address={address}
								withdrawType={withdrawType}
								{...NewAddressStepVariants[withdrawType as WithdrawType]}
								network={network}
								onAddressChange={onAddressChange}
							/>
						) : (
							<FrequentAddressesStep
								addresses={addresses || []}
								onConfirmAddress={onConfirmAddress}
								handleNewAddress={() => setNewAddress(true)}
							/>
						)}
					</>
				) : (
					<Skeleton className="h-[130px] w-full" />
				)}

				<SheetContent>
					<Col className="gap-belowSectionHeader">
						<SectionHeader
							title="Moneda"
							subtitle="Seleccioná la moneda con la que quieras realizar el cobro"
						></SectionHeader>
						<Section className="gap-betweenOptions">
							<Option
								title={fiatAddressDetails.fullName}
								description={fiatAddressDetails.cbu}
								onClick={() => onConfirmAddress(undefined, fiatAddressDetails)}
							></Option>
						</Section>
					</Col>
				</SheetContent>
			</Sheet>
		</CenteredBody>
	)
}

export default SelectAddressStep
