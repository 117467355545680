import { useState } from "react"
import {
	FiatAddressDetails,
	WithdrawSteps,
	WithdrawWizardData,
} from "../../pages/withdraw/withdraw-wizard-types"
import { BalanceItem } from "../../utils/balances"
import EmptyWallet from "../app/wallet/EmptyWallet"
import WithdrawConfirmationModal from "../modals/withdraw-confirmation/WithdrawConfirmationModal"
import { Price } from "../tables/transactions-table/columns"
import Col from "../ui/layouts/column"
import CenteredBody from "../ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../ui/section-header"
import ConfirmWithdrawStep from "./ConfirmWithdrawStep"

import { WithdrawType } from "../../utils/withdrawCurrencies"
import EnterAmountStep from "./EnterAmountStep"
import EnterAmountStepCrypto from "./EnterAmountStepCrypto"
import LoadingWithdrawStep from "./LoadingWithdrawStep"
import SelectAddressStep, { SavedAddress } from "./SelectAddressStep"
import SelectCurrencyStep from "./SelectCurrencyStep"
import SelectNetworkStep from "./SelectNetworkStep"
import SelectWithdrawTypeStep from "./SelectWithdrawTypeStep"

const WithdrawWizardBody = ({
	wizardData,
	onDataChanged,
	balances,
	networkBalances,
	prices,
	handleConfirmTx,
	addresses,
	handleNewAddress,
}: {
	wizardData: WithdrawWizardData
	onDataChanged: (wizardData: WithdrawWizardData) => void
	balances: BalanceItem[]
	networkBalances: BalanceItem[]
	prices?: Price[]
	handleConfirmTx: () => void
	addresses?: SavedAddress[]
	handleNewAddress: ({
		address,
		alias,
	}: {
		address: string
		alias: string
	}) => void
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const bodys = {
		[WithdrawSteps.SelectType]: (
			<SelectWithdrawTypeStep
				balances={balances}
				onWithdrawTypeSelected={(type: WithdrawType, step: WithdrawSteps) =>
					onDataChanged({
						...wizardData,
						step: step,
						data: {
							...wizardData.data,
							network:
								type === "crypto"
									? "BSC"
									: type === "pix"
									? "POLYGON"
									: "POLLUX",
							currency:
								type === "pix"
									? "USDT"
									: type === "transfer"
									? "ARS"
									: undefined,
							withdrawType: type,
						},
					})
				}
			/>
		),
		[WithdrawSteps.SelectCurrency]: (
			<SelectCurrencyStep
				balances={balances?.filter(
					(balance) =>
						balance.network === "BSC" || balance.network === "POLYGON"
				)}
				onCurrencySelected={(currency: string, network: string) =>
					onDataChanged({
						...wizardData,
						step: WithdrawSteps.SelectNetwork,
						data: {
							...wizardData.data,
							currency: currency,
							network: network,
						},
					})
				}
			/>
		),
		[WithdrawSteps.SelectNetwork]: (
			<SelectNetworkStep
				networkBalances={networkBalances}
				onNetworkSelected={(network: string) =>
					onDataChanged({
						...wizardData,
						step: WithdrawSteps.SelectAddress,
						data: {
							...wizardData.data,
							network: network,
						},
					})
				}
			/>
		),
		[WithdrawSteps.SelectAddress]: (
			<SelectAddressStep
				onNewAddress={handleNewAddress}
				addresses={addresses}
				network={wizardData.data.network}
				withdrawType={wizardData.data.withdrawType ?? "crypto"}
				onConfirmAddress={(
					address?: string,
					fiatAddressDetails?: FiatAddressDetails
				) => {
					if (address) {
						onDataChanged({
							...wizardData,
							step: WithdrawSteps.EnterAmount,
							data: {
								...wizardData.data,
								address: address,
								fiatAddressDetails: fiatAddressDetails,
							},
						})
					} else {
						onDataChanged({
							...wizardData,
							data: {
								...wizardData.data,
								fiatAddressDetails: fiatAddressDetails,
							},
							step: WithdrawSteps.EnterAmount,
						})
					}
				}}
				address={wizardData.data.address}
				onAddressChange={(address: string) =>
					onDataChanged({
						...wizardData,
						data: {
							...wizardData.data,
							address: address,
						},
					})
				}
			/>
		),
		[WithdrawSteps.EnterAmount]:
			wizardData.data.withdrawType === "crypto" ||
			wizardData.data.withdrawType === "pix" ? (
				<EnterAmountStepCrypto
					balanceAvailable={balances.find(
						(bal) =>
							bal.network === wizardData.data.network &&
							bal.currency === wizardData.data.currency
					)}
					onAmountChange={(newP) =>
						onDataChanged({
							...wizardData,
							data: {
								...wizardData.data,
								amount: newP,
							},
						})
					}
					quote={wizardData.data.quote}
					onConfirmAmount={() => setIsModalOpen(true)}
					prices={prices}
					loading={wizardData.loading}
				/>
			) : (
				<EnterAmountStep
					balanceAvailable={networkBalances.find(
						(bal) => bal.network === wizardData.data.network
					)}
					onAmountChange={(newP) =>
						onDataChanged({
							...wizardData,
							data: {
								...wizardData.data,
								amount: newP,
							},
						})
					}
					onConfirmAmount={() => setIsModalOpen(true)}
					loading={wizardData.loading}
				/>
			),
		[WithdrawSteps.Loading]: <LoadingWithdrawStep />,
		[WithdrawSteps.Success]: (
			<ConfirmWithdrawStep transaction_id={wizardData.data.transaction_id} />
		),
	}
	console.log(wizardData.data)
	return (
		<CenteredBody>
			<WithdrawConfirmationModal
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				handleConfirm={() => {
					setIsModalOpen(false)
					handleConfirmTx()
				}}
				wizardData={wizardData}
			/>
			{balances?.length > 0 ? (
				bodys[wizardData.step]
			) : (
				<Col className="gap-betweenSections">
					<SectionHeader title="No tienes monedas para retirar" />
					<EmptyWallet />
				</Col>
			)}
		</CenteredBody>
	)
}

export default WithdrawWizardBody
