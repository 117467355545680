import { ChevronsUpDown } from "lucide-react"
import { Suspense, lazy, useEffect, useState } from "react"
import { Price } from "../../tables/transactions-table/columns"
import { Button } from "../button"
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandItem,
	CommandList,
} from "../command"
import { Popover, PopoverContent, PopoverTrigger } from "../popover"
const CurrencyInput = lazy(() => import("react-currency-input-field"))

const AmountInput = ({
	onAmountChange,
	currencies = ["ARS", "USD", "BRL"],
	value,
}: {
	onAmountChange: (newPrice: Price) => void
	currencies?: string[]
	value?: Price
}) => {
	const [open, setOpen] = useState(false)
	const [price, setPrice] = useState({
		amount: value?.amount || "",
		currency: value?.currency ?? "ARS",
	})

	useEffect(() => {
		if (value) {
			setPrice({
				amount: value.amount,
				currency: value.currency,
			})
		}
	}, [value?.amount, value?.currency])

	return (
		<div className="flex w-full items-center space-x-2">
			<form className="flex w-full">
				<div className="flex-row flex items-end flex-nowrap w-full">
					<Suspense>
						<CurrencyInput
							id="currency-input"
							name="Monto"
							placeholder={
								price?.currency === "BRL" ? "Insira o valor" : "Enter amount"
							}
							required
							prefix={
								price.currency === "ARS" ||
								price.currency === "BRL" ||
								price.currency === "USD"
									? "$"
									: ""
							}
							allowDecimals
							decimalsLimit={
								price.currency === "ARS" ||
								price.currency === "BRL" ||
								price.currency === "USD"
									? 2
									: 5
							}
							datatype="float"
							decimalSeparator="."
							decimalScale={
								price.currency === "ARS" ||
								price.currency === "BRL" ||
								price.currency === "USD"
									? 2
									: 5
							}
							groupSeparator=","
							className="rounded-r-none border-r-0 border border-border h-full w-full focus:ring-0 focus:outline-none bg-background-subtle px-sm rounded-xs py-xs"
							value={price.amount}
							onValueChange={(value, name, values) => {
								if (values) {
									if (values.float !== undefined && values.float !== null) {
										setPrice({
											amount: values.float, // Store raw value
											currency: price.currency,
										})

										onAmountChange({
											amount: value!, // Store float value
											currency: price.currency,
										})
									}
								}
							}}
							data-test="amount-input"
						/>
					</Suspense>
					<Popover open={open} onOpenChange={setOpen}>
						<PopoverTrigger asChild>
							<Button
								variant="outline"
								role="combobox"
								size="lg"
								aria-expanded={open}
								className="rounded-l-none border-l-0"
							>
								{price.currency}
								<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
							</Button>
						</PopoverTrigger>
						<PopoverContent className="w-fit p-2">
							<Command value={price.currency}>
								<CommandList>
									<CommandEmpty>No hay monedas.</CommandEmpty>
									<CommandGroup>
										{currencies.map((currency) => (
											<CommandItem
												key={currency}
												onSelect={(val: string) => {
													const newPrice = {
														amount: price.amount || "0",
														currency: val,
													}
													setPrice(newPrice)
													onAmountChange(newPrice)
												}}
											>
												<div className="flex flex-row gap-2">
													<img
														alt={currency}
														src={`/currencies/${currency.toLowerCase()}-icon.svg`}
													/>
													{currency}
												</div>
											</CommandItem>
										))}
									</CommandGroup>
								</CommandList>
							</Command>
						</PopoverContent>
					</Popover>
				</div>
			</form>
		</div>
	)
}

export default AmountInput
