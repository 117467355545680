import { useQuery } from "@tanstack/react-query"
import { getUserStores } from "../services/users"

export default function useFetchStores(user_id: string | undefined) {
	return useQuery({
		queryKey: ["stores"],
		queryFn: async () => {
			if (!user_id) {
				throw new Error("User ID not provided")
			}
			const { data } = await getUserStores(user_id)
			return data.data
		},
	})
}
