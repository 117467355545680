import { useState } from "react"
import {
	OnboardingPersonalSteps,
	OnboardingPersonalWizardData,
} from "./onboard-personal-wizard-types.ts"
import PersonalBody from "./PersonalBody"

export default function PersonalWizard() {
	const [wizardData, setWizardData] = useState<OnboardingPersonalWizardData>({
		step: OnboardingPersonalSteps.SpecialAgreements,
		loading: false,
		data: {
			entity_type: "F",
			first_name: "",
			last_name: "",
			document_type: "CUIT",
			cuit: "",
			phone: "",
			address: "",
			uif_conditions: {
				obligated_subject: false,
				exposed_person: false,
				fatca_subject: false,
				ocde_subject: false,
			},
		},
		completionPercentage: 10,
	})

	return <PersonalBody setWizardData={setWizardData} wizardData={wizardData} />
}
