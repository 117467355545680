import { SquareMenu } from "lucide-react"
import { WoocommercePaymentInfo } from "../../../../tables/transactions-table/columns"
import { Button } from "../../../../ui/button"
import Spacer from "../../../../ui/spacer"
import BodyBlock from "../BodyBlock"
import BodyItem from "../BodyItem"

const WoocommercePaymentDetails = ({
	info,
	store_url,
}: {
	info: WoocommercePaymentInfo
	store_url?: string
}) => {
	return (
		<BodyBlock title="Woocommerce">
			<BodyItem name="ID de orden" value={`${info.order_id}`} />
			{info.client_email && (
				<BodyItem
					name="Email del cliente"
					value={info.client_email}
					overflow={true}
				/>
			)}
			{store_url && info.order_id && (
				<Button asChild variant="outline" size="full">
					<a
						href={`https://${store_url}/wp-admin/admin.php?page=wc-orders&action=edit&id=${info.order_id}`}
						target="_blank"
						rel="noreferrer"
					>
						Ir a la orden
						<Spacer axis="horizontal" type="betweenTexts" />
						<SquareMenu className="size-icon" />
					</a>
				</Button>
			)}
		</BodyBlock>
	)
}

export default WoocommercePaymentDetails
