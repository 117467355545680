import { ChevronRight } from "lucide-react"
import { cn } from "../../../lib/utils"
import LoadableButton from "../buttons/LoadableButton"
import Row from "../layouts/row"
import { Switch } from "../switch"
import { Body1, Heading6 } from "../texts/TextStyle"

interface OptionProps {
	title: string
	description?: string
	icon?: React.ReactNode
	div?: React.ReactNode
	trailing?: "arrow" | "switch" | "button" | "div" | "none"
	subleading?: string
	leading?: "arrow" | "switch" | "button" | "div" | "none"
	buttonText?: string
	onClick?: () => void
	disabled?: boolean
	iconInside?: boolean // New prop to determine whether icon should be inside or outside
	//Props for leading switch
	switchChecked?: boolean
	onCheckedChange?: (checked: boolean) => void
	buttonProps?: any
	className?: string
	"data-test"?: string
}

const Option: React.FC<OptionProps> = ({
	title,
	description,
	trailing,
	subleading,
	leading,
	div,
	icon,
	onClick,
	buttonText,
	disabled = false,
	iconInside = true, // Set default value for iconInside prop
	switchChecked,
	onCheckedChange,
	buttonProps = {},
	className,
	"data-test": dataTest,
}) => {
	const classes = className
		?.split(" ")
		.reduce<{ title: string[]; description: string[]; main: string[] }>(
			(acc, curr) => {
				if (curr.startsWith("title:")) {
					acc.title.push(curr.replace("title:", ""))
				} else if (curr.startsWith("description:")) {
					acc.description.push(curr.replace("description:", ""))
				} else {
					acc.main.push(curr)
				}
				return acc
			},
			{ main: [], title: [], description: [] }
		) || { main: [], title: [], description: [] }

	let leadingIcon: React.ReactNode = null

	switch (leading) {
		case "arrow":
			leadingIcon = <ChevronRight />
			break
		case "switch":
			leadingIcon = (
				<Switch checked={switchChecked} onCheckedChange={onCheckedChange} />
			)
			break
		case "button":
			leadingIcon = (
				<LoadableButton onClick={onClick} {...buttonProps}>
					{buttonText}
				</LoadableButton>
			)
			break
		case "div":
			leadingIcon = <div>{div}</div>
			break
		default:
			leadingIcon = null
			break
	}

	const handleClick = () => {
		if (onClick && !disabled) {
			onClick()
		}
	}

	return (
		<div
			className={cn(
				`min-h-[80px] group w-full flex justify-between items-center rounded-sm border p-sm ${
					onClick
						? "hover:bg-background hover:border-neutral-900 hover:transition-all hover:duration-500 cursor-pointer"
						: ""
				} ${
					disabled
						? "opacity-30 bg-transparent border-border-disabled cursor-not-allowed pointer-events-none"
						: onClick
						? "bg-background-subtle cursor-pointer"
						: "bg-background-subtle"
				}`,
				...classes.main
			)}
			onClick={leading !== "button" ? handleClick : undefined}
			data-test={dataTest}
		>
			<div className="flex grid-flow-row gap-betweenComponents items-center ">
				{iconInside && icon && (
					<div className="rounded-full [&>*]:size-[18px] [&>*]:stroke-[2.5px] group-hover:animate-[wiggle_1s_ease-in-out_infinite] size-md bg-background-strong grid justify-center items-center">
						{icon}
					</div>
				)}
				{!iconInside && icon && (
					<div className="size-md grid [&>*]:size-[32px] justify-center items-center">
						{icon}
					</div>
				)}
				{trailing === "switch" && (
					<Switch
						checked={switchChecked}
						onCheckedChange={onCheckedChange}
						variant="expanded"
						className="group-hover:border-background-invers group-hover:border transition-all duration-200"
					></Switch>
				)}
				<div className="flex flex-col  gap-1">
					<Heading6
						className={cn(
							"font-semibold leading-none group-hover:translate-x-1 transition duration-400",
							...classes.title
						)}
					>
						{title}
					</Heading6>
					{description && (
						<Body1
							className={cn(
								"font-medium text-text-muted group-hover:translate-x-1 transition duration-500",
								...classes.description
							)}
						>
							{description}
						</Body1>
					)}
				</div>
			</div>
			<Row className="gap-betweenTexts items-center">
				<Heading6 className="text-text-muted font-normal ">
					{subleading}
				</Heading6>
				{leadingIcon}
			</Row>
		</div>
	)
}

export { Option }
