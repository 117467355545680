import { FlaskConical } from "lucide-react"
import React from "react"

const BetaSnack: React.FC = () => {
	return (
		<div className="absolute right-screenMobile md:right-14 top-screenMobile bg-secondary w-fit rounded-md flex flex-row gap-betweenTexts text-h6 font-medium text-text px-md py-sm  justify-center items-center">
			<FlaskConical className="size-icon" />
			Beta
		</div>
	)
}

export default BetaSnack
