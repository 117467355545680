import { useEffect, useState } from "react"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import OptionCard from "../../../components/ui/options/option-card"
import { Section } from "../../../components/ui/section"
import { SectionHeader } from "../../../components/ui/section-header"
import { Skeleton } from "../../../components/ui/skeleton"
import { useAuth } from "../../../context/AuthContext"
import { getPricesWithDifferentPricingProfiles } from "../../../services/prices"
import { updateUser } from "../../../services/users"

const pricing_profiles = [
	{ title: "vendedor", id: "seller" },
	{ title: "promedio", id: "average" },
	{ title: "comprador", id: "buyer" },
]

const selectedProfileClassName =
	"outline outline-2 outline-onSecondary capitalize"
const notSelectedProfileClassName =
	"group/slide  hover:bg-background-elevated hover:outline hover:outline-1 hover:outline-border capitalize"

const PricingProfileBody = () => {
	const { user, updateUser: updateUserStore } = useAuth()
	const [prices, setPrices] = useState<{
		vendedor: string
		comprador: string
		promedio: string
	}>()

	const [selectedProfile, setSelectedProfile] = useState(
		user?.pricing_profile || "average"
	)

	useEffect(() => {
		const getPrices = async () => {
			const prices_res = await getPricesWithDifferentPricingProfiles()
			console.log("Prices RES:", prices_res)
			setPrices(prices_res)
		}
		if (!prices) {
			getPrices()
		}
	}, [prices])

	const onClickSelectedProfile = (value: string) => {
		setSelectedProfile(value)
		updateUser(user?.id, { pricing_profile: value })
		updateUserStore({ ...user, pricing_profile: value })
	}

	return (
		<CenteredBody>
			<SectionHeader
				title="Elegí tu cotización"
				subtitle="Estas por cambiar la taza de cambio que se genera a la hora de vender un producto."
			></SectionHeader>
			<Section
				title="Dolar Cripto"
				footer="Los cambios se verán reflejados en tus próximas transacciones. "
			>
				<div className="flex flex-row justify-between gap-betweenOptions border border-border rounded-md p-xs">
					{pricing_profiles.map(
						(elem: { title: string; id: string }, index) => {
							const price = prices && prices[elem.title as keyof typeof prices]
							const isSelected = selectedProfile === elem.id
							return (
								<OptionCard
									key={"pricing_profile_" + elem.id}
									header={
										!price ? (
											<Skeleton className="w-15 h-3 bg-background-strong" />
										) : (
											`≈${price} ARS`
										)
									}
									footer={elem.title}
									className={
										isSelected
											? selectedProfileClassName
											: notSelectedProfileClassName
									}
									onClick={() => onClickSelectedProfile(elem.id)}
								/>
							)
						}
					)}
					{/* <OptionCard
						header="≈1.100,00 ARS"
						footer="Compra"
						className="group/slide  hover:bg-background-elevated hover:outline hover:outline-1 hover:outline-border"
						onClick={() => console.log("Compra clicked")}
					></OptionCard>
					<OptionCard
						header="≈1.100,00 ARS"
						footer="Promedio"
						className="group/slide hover:bg-background-elevated hover:outline hover:outline-1 hover:outline-border"
						onClick={() => console.log("Promedio clicked")}
					></OptionCard>
					<OptionCard
						header="≈1.100,00 ARS"
						footer="Venta"
						className="outline outline-2 outline-onSecondary "
						onClick={() => console.log("Venta clicked")}
					></OptionCard> */}
				</div>
			</Section>
		</CenteredBody>
	)
}

export default PricingProfileBody
