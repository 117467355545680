import {
	createUserWithEmailAndPassword,
	GoogleAuthProvider,
	onAuthStateChanged,
	onIdTokenChanged,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signInWithPopup,
	signOut,
} from "firebase/auth"
import { createContext, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { auth } from "../config/firebase"
import { useGlobalStore } from "../hooks/useGlobalStore"
import { PrivateRole, PublicRole } from "../lib/roles"
import {
	removeInterceptors,
	setInterceptor,
	updateToken,
} from "../services/interceptors"
import { getUserStores } from "../services/users"
import logger from "../utils/logger"
import { useFetchBalances } from "../hooks/useFetchBalances"
import { useRefreshAuth } from "../hooks/useRefreshAuth"
import { UseQueryResult } from "@tanstack/react-query"
import { KycGetResponse } from "../pages/onboarding/personal/onboard-personal-wizard-types.ts"
import { useGetKyc } from "../pages/onboarding/personal/useGetKyc.tsx"
import useFetchStores from "../hooks/useFetchStores.ts"

export type AuthContext = {
	user: any
	firebaseUser: any
	login: (email: string, password: string) => Promise<any>
	signup: (email: string, password: string) => Promise<any>
	logout: () => Promise<void>
	signInWithGoogle: () => Promise<any>
	getErrorMessage: (errorCode: string) => string
	updateUser: (taloUser: any) => void
	hasPrivilege: (
		minimumPrivateRole: PrivateRole,
		minimumPublicRole: PublicRole
	) => boolean
	balances: any
	totalBalance: any
	stores: any
	fetchBalance: () => void
	fetchStores: () => void
	resetPassword: (email: string) => Promise<void>
	kyc: UseQueryResult<KycGetResponse, Error>
}

const AuthContext = createContext<AuthContext>({
	user: null,
	firebaseUser: null,
	login: async () => {},
	signup: async () => {},
	logout: async () => {},
	signInWithGoogle: async () => {},
	getErrorMessage: () => "",
	updateUser: () => {},
	hasPrivilege: () => false,
	balances: null,
	totalBalance: null,
	stores: [],
	fetchBalance: () => {},
	fetchStores: () => {},
	resetPassword: async () => {},
	kyc: {} as UseQueryResult<KycGetResponse, Error>,
})

export const useAuth = () => useContext(AuthContext)

const googleAuthProvider = new GoogleAuthProvider()

export const AuthContextProvider = ({
	children,
}: {
	children: React.ReactNode
}) => {
	const [user, setUser] = useState<any>(null)
	const [firebaseUser, setFirebaseUser] = useState<any>(null)
	const [loading, setLoading] = useState(true)
	const [interceptorsArray, setInterceptorsArray] = useState<any[]>([])
	const [balances, setBalances] = useState<any>(null)
	const [totalBalance, setTotalBalance] = useState<any>(null)

	const { setGlobalStore } = useGlobalStore()
	const kyc = useGetKyc(user?.user_id)
	const stores = useFetchStores(user?.user_id)

	const navigate = useNavigate()

	const { data: balanceData, refetch: refetchBalances } = useFetchBalances(
		user?.user_id,
		user?.roles,
		user?.roles?.includes("pos") ? user.team_id : undefined
	)

	useEffect(() => {
		if (balanceData) {
			setBalances(balanceData.balances)
			setTotalBalance(balanceData.totalBalance)
		}
	}, [balanceData])

	const {
		data: authData,
		isSuccess: isAuthSuccess,
		isLoading,
	} = useRefreshAuth(firebaseUser)

	useEffect(() => {
		if (isAuthSuccess && authData) {
			setUser(authData)
			setLoading(false)
		}
	}, [isAuthSuccess, authData])

	useEffect(() => {
		const setInterceptorAsync = async () => {
			const tokenResult = await firebaseUser.getIdTokenResult()
			const interceptor = setInterceptor(
				tokenResult,
				tokenResult.expirationTime,
				async () => {
					return await firebaseUser.getIdTokenResult()
				}
			)
			setInterceptorsArray([...interceptorsArray, interceptor])
		}
		if (firebaseUser) {
			removeInterceptors(interceptorsArray)
			setInterceptorAsync()
		}
	}, [firebaseUser])

	useEffect(() => {
		const unsubscribeAuthState = onAuthStateChanged(auth, (user) => {
			if (user) {
				setFirebaseUser(user)
			} else {
				setFirebaseUser(null)
				setUser(null)
				setLoading(false)
			}
		})
		return () => unsubscribeAuthState()
	}, [])

	useEffect(() => {
		const unsubscribeIdToken = onIdTokenChanged(auth, (user) => {
			if (user) {
				setFirebaseUser(user)
			} else {
				setFirebaseUser(null)
				setUser(null)
				setLoading(false)
			}
		})
		return () => unsubscribeIdToken()
	}, [])

	useEffect(() => {
		if (isAuthSuccess) {
			setLoading(false)
		}
	}, [isAuthSuccess])

	useEffect(() => {
		if (user) {
			refetchBalances()
			stores.refetch()
		}
	}, [user])

	const signup = (email: string, password: string) => {
		return createUserWithEmailAndPassword(auth, email, password)
	}

	const login = (email: string, password: string) => {
		return signInWithEmailAndPassword(auth, email, password)
	}

	const signInWithGoogle = () => {
		return signInWithPopup(auth, googleAuthProvider)
	}

	const resetPassword = (email: string) => {
		return sendPasswordResetEmail(auth, email)
	}

	const logout = async () => {
		navigate("/login")
		setUser(null)
		updateToken("")
		setBalances(null)
		setGlobalStore({})
		await signOut(auth)
	}

	const updateUser = (taloUser: any) => {
		logger.info(JSON.stringify(taloUser))
		setUser({
			...user,
			emailVerified: taloUser.email_verified,
			fiat_payments_enabled: taloUser.fiat_payments_enabled,
			pricing_profile: taloUser.pricing_profile,
		})
	}

	const hasPrivilege = (
		minimumPrivateRole: PrivateRole,
		minimumPublicRole: PublicRole
	) => {
		const privateRoles = ["yoda", "jedi", "r2d2", "padawan", "clone"]
		const publicRoles = ["owner", "supervisor", "pos"]
		//map to index array and find minimum
		if (!user) return false
		if (minimumPrivateRole) {
			//check if user has at least minimum private role
			const authRoleIndex = Math.min(
				...user?.roles
					?.map((role: string) =>
						privateRoles.findIndex((value, index) => role === value)
					)
					?.filter((value: number) => value !== -1)
			)
			const minimumPrivateRoleIndex = privateRoles.findIndex(
				(value, index) => minimumPrivateRole === value
			)
			if (authRoleIndex <= minimumPrivateRoleIndex) {
				return true
			}
		}

		if (minimumPublicRole) {
			//check if user has at least minimum public role
			const authRoleIndex = Math.min(
				...user.roles
					.map((role: string) =>
						publicRoles.findIndex((value, index) => role === value)
					)
					.filter((value: number) => value !== -1)
			)
			const minimumPublicRoleIndex = publicRoles.findIndex(
				(value, index) => minimumPublicRole === value
			)

			if (authRoleIndex <= minimumPublicRoleIndex) {
				return true
			}
		}

		return false
	}

	// Error messages for every authentication error type
	const errorMessages: { [key: string]: string } = {
		"auth/admin-restricted-operation": "auth.errors.adminRestrictedOperation",
		"auth/argument-error": "auth.errors.argumentError",
		"auth/app-not-authorized": "auth.errors.appNotAuthorized",
		"auth/app-not-installed": "auth.errors.appNotInstalled",
		"auth/captcha-check-failed": "auth.errors.captchaCheckFailed",
		"auth/code-expired": "auth.errors.codeExpired",
		"auth/cordova-not-ready": "auth.errors.cordovaNotReady",
		"auth/cors-unsupported": "auth.errors.corsUnsupported",
		// "auth/credential-already-in-use": "auth.errors.credentialAlreadyInUse",
		"auth/credential-already-in-use": "Las credenciales ya están en uso",
		"auth/custom-token-mismatch": "auth.errors.customTokenMismatch",
		"auth/requires-recent-login": "auth.errors.requiresRecentLogin",
		"auth/dependent-sdk-initialized-before-auth":
			"auth.errors.dependentSdkInitializedBeforeAuth",
		"auth/dynamic-link-not-activated": "auth.errors.dynamicLinkNotActivated",
		// "auth/email-already-in-use": "auth.errors.emailAlreadyInUse",
		"auth/email-already-in-use": "El correo electrónico ya está en uso",
		"auth/emulator-config-failed": "auth.errors.emulatorConfigFailed",
		"auth/expired-action-code": "auth.errors.expiredActionCode",
		"auth/cancelled-popup-request": "auth.errors.cancelledPopupRequest",
		"auth/internal-error": "auth.errors.internalError",
		"auth/invalid-api-key": "auth.errors.invalidApiKey",
		"auth/invalid-app-credential": "auth.errors.invalidAppCredential",
		"auth/invalid-app-id": "auth.errors.invalidAppId",
		"auth/invalid-user-token": "auth.errors.invalidUserToken",
		"auth/invalid-auth-event": "auth.errors.invalidAuthEvent",
		"auth/invalid-cert-hash": "auth.errors.invalidCertHash",
		"auth/invalid-verification-code": "auth.errors.invalidVerificationCode",
		"auth/invalid-continue-uri": "auth.errors.invalidContinueUri",
		"auth/invalid-cordova-configuration":
			"auth.errors.invalidCordovaConfiguration",
		"auth/invalid-custom-token": "auth.errors.invalidCustomToken",
		"auth/invalid-dynamic-link-domain": "auth.errors.invalidDynamicLinkDomain",
		// "auth/invalid-email": "auth.errors.invalidEmail",
		"auth/invalid-email": "El correo electrónico no es válido",
		"auth/invalid-emulator-scheme": "auth.errors.invalidEmulatorScheme",
		"auth/invalid-credential": "auth.errors.invalidCredential",
		"auth/invalid-message-payload": "auth.errors.invalidMessagePayload",
		"auth/invalid-multi-factor-session":
			"auth.errors.invalidMultiFactorSession",
		"auth/invalid-oauth-client-id": "auth.errors.invalidOauthClientId",
		"auth/invalid-oauth-provider": "auth.errors.invalidOauthProvider",
		"auth/invalid-action-code": "auth.errors.invalidActionCode",
		"auth/unauthorized-domain": "auth.errors.unauthorizedDomain",
		"auth/passwords-dont-match": "Las contraseñas no coinciden",
		// "auth/wrong-password": "auth.errors.wrongPassword",
		"auth/wrong-password": "Usuario o contraseña incorrectos.",
		"auth/invalid-persistence-type": "auth.errors.invalidPersistenceType",
		"auth/invalid-phone-number": "auth.errors.invalidPhoneNumber",
		"auth/invalid-provider-id": "auth.errors.invalidProviderId",
		"auth/invalid-recipient-email": "auth.errors.invalidRecipientEmail",
		"auth/invalid-sender": "auth.errors.invalidSender",
		"auth/invalid-verification-id": "auth.errors.invalidVerificationId",
		"auth/invalid-tenant-id": "auth.errors.invalidTenantId",
		"auth/multi-factor-info-not-found": "auth.errors.multiFactorInfoNotFound",
		"auth/multi-factor-auth-required": "auth.errors.multiFactorAuthRequired",
		"auth/missing-android-pkg-name": "auth.errors.missingAndroidPkgName",
		"auth/missing-app-credential": "auth.errors.missingAppCredential",
		"auth/auth-domain-config-required": "auth.errors.authDomainConfigRequired",
		"auth/missing-verification-code": "auth.errors.missingVerificationCode",
		"auth/missing-continue-uri": "auth.errors.missingContinueUri",
		"auth/missing-iframe-start": "auth.errors.missingIframeStart",
		"auth/missing-ios-bundle-id": "auth.errors.missingIosBundleId",
		"auth/missing-or-invalid-nonce": "auth.errors.missingOrInvalidNonce",
		"auth/missing-multi-factor-info": "auth.errors.missingMultiFactorInfo",
		"auth/missing-multi-factor-session":
			"auth.errors.missingMultiFactorSession",
		"auth/missing-phone-number": "auth.errors.missingPhoneNumber",
		"auth/missing-verification-id": "auth.errors.missingVerificationId",
		"auth/app-deleted": "auth.errors.appDeleted",
		"auth/account-exists-with-different-credential":
			"auth.errors.accountExistsWithDifferentCredential",
		// "auth/network-request-failed": "auth.errors.networkRequestFailed",
		"auth/network-request-failed": "Error haciendo el pedido a la red",
		"auth/null-user": "auth.errors.nullUser",
		"auth/no-auth-event": "auth.errors.noAuthEvent",
		"auth/no-such-provider": "auth.errors.noSuchProvider",
		"auth/operation-not-allowed": "auth.errors.operationNotAllowed",
		"auth/operation-not-supported-in-this-environment":
			"auth.errors.operationNotSupportedInThisEnvironment",
		"auth/popup-blocked": "auth.errors.popupBlocked",
		"auth/popup-closed-by-user": "auth.errors.popupClosedByUser",
		"auth/provider-already-linked": "auth.errors.providerAlreadyLinked",
		"auth/quota-exceeded": "auth.errors.quotaExceeded",
		"auth/redirect-cancelled-by-user": "auth.errors.redirectCancelledByUser",
		"auth/redirect-operation-pending": "auth.errors.redirectOperationPending",
		"auth/rejected-credential": "auth.errors.rejectedCredential",
		"auth/second-factor-already-in-use": "auth.errors.secondFactorAlreadyInUse",
		"auth/maximum-second-factor-count-exceeded":
			"auth.errors.maximumSecondFactorCountExceeded",
		"auth/tenant-id-mismatch": "auth.errors.tenantIdMismatch",
		"auth/timeout": "auth.errors.timeout",
		"auth/user-token-expired": "auth.errors.userTokenExpired",
		// "auth/too-many-requests": "auth.errors.tooManyRequests",
		"auth/too-many-requests":
			"El usuario ha sido bloqueado por demasiados intentos de inicio de sesión.",
		"auth/unauthorized-continue-uri": "auth.errors.unauthorizedContinueUri",
		"auth/unsupported-first-factor": "auth.errors.unsupportedFirstFactor",
		"auth/unsupported-persistence-type":
			"auth.errors.unsupportedPersistenceType",
		"auth/unsupported-tenant-operation":
			"auth.errors.unsupportedTenantOperation",
		// "auth/unverified-email": "auth.errors.unverifiedEmail",
		"auth/unverified-email": "El correo electrónico no ha sido verificado",
		// "auth/user-cancelled": "auth.errors.userCancelled",
		"auth/user-cancelled": "El usuario ha sido cancelado",
		// "auth/user-not-found": "auth.errors.userNotFound",
		"auth/user-not-found": "El usuario no existe",
		// "auth/user-disabled": "auth.errors.userDisabled",
		"auth/user-disabled": "El usuario está deshabilitado",
		"auth/user-mismatch": "auth.errors.userMismatch",
		// "auth/user-signed-out": "auth.errors.userSignedOut",
		"auth/user-signed-out": "El usuario ha cerrado sesión",
		"auth/weak-password": "La contraseña es débil",
		"auth/web-storage-unsupported": "auth.errors.webStorageUnsupported",
		"auth/already-initialized": "auth.errors.alreadyInitialized",
	}

	// Get the error message for the provided error code.
	function getErrorMessage(errorCode: string): string {
		return errorMessages[errorCode]
	}

	return (
		<AuthContext.Provider
			value={{
				user,
				firebaseUser,
				login,
				signup,
				logout,
				signInWithGoogle,
				getErrorMessage,
				updateUser,
				hasPrivilege,
				balances,
				totalBalance,
				stores : stores.data?.stores,
				fetchBalance: refetchBalances,
				fetchStores : stores.refetch,
				resetPassword,
				kyc,
			}}
		>
			{loading ? null : children}
		</AuthContext.Provider>
	)
}
