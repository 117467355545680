import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import FeatureLayout from "../../../components/ui/layouts/platform/FeatureLayout"
import { CryptoCover } from "../../settings/payment-methods/PaymentsCover"
import { KeyRound, Puzzle, RectangleEllipsis } from "lucide-react"
import { Alert } from "../../../components/ui/alert"
import { SettingsSectionItem } from "../../settings/items"
import PluginsSection from "./PluginSection"
import { Button } from "../../../components/ui/button"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../../context/AuthContext"
import { useEffect, useState } from "react"

import { Section } from "../../../components/ui/section"
import { Option } from "../../../components/ui/options/option"
import { StoreType } from "../../../services/users"
import { redirectToEndOfOnboarding } from "../../../lib/utils"

export interface Plugin {
	title: string
	icon: React.ReactNode
}

const integrationsAvailable: { [key in StoreType]: Plugin } = {
	tiendanube: {
		title: "Tiendanube + Talo Pay",
		icon: <img src="/dashboard/plugins/tiendanube.svg" className="size-full" />,
	},
	woocommerce: {
		title: "Woocommerce + Talo Pay",
		icon: (
			<img src="/dashboard/plugins/woocommerce.svg" className="size-full" />
		),
	},
}

const CryptoBody = ({
	subSections,
}: {
	subSections: SettingsSectionItem[]
}) => {
	const navigate = useNavigate()
	const { user, stores } = useAuth()
	const [isTiendanubeInstalled, setIsTiendanubeInstalled] = useState(false)
	const [isWooCommerceInstalled, setIsWooCommerceInstalled] = useState(false)

	useEffect(() => {
		if (stores) {
			if (process.env.REACT_APP_TIENDANUBE_APP_ID === undefined) {
				return
			}

			const isTiendanubeStore = stores.some(
				(store: any) =>
					store.store_type === StoreType.TIENDANUBE &&
					store.store_apps.find(
						(app: any) =>
							app.app_id === process.env.REACT_APP_TIENDANUBE_APP_ID ||
							app.app_id === process.env.REACT_APP_TIENDANUBE_HOMO_APP_ID
					)
			)

			if (isTiendanubeStore) {
				setIsTiendanubeInstalled(true)
			}

			const isWooCommerceStore = stores.some(
				(store: any) => store.store_type === StoreType.WOOCOMMERCE
			)

			if (isWooCommerceStore) {
				setIsWooCommerceInstalled(true)
			}
		}
	}, [stores])

	return (
		<CenteredBody>
			<FeatureLayout
				coverImage={<CryptoCover />}
				sectionHeaderProps={{
					title: "Pagos en criptomonedas",
					subtitle:
						"Acepta criptomonedas desde cualquier billetera y expande te a nuevos clientes. Conectá tu cuenta de Talo a tu tienda online para agregar el botón de pagos y aceptar pagos de todo el mundo.",
				}}
			>
				<Alert
					variant="link"
					icon={
						<div className="rounded-full [&>*]:size-[18px] [&>*]:stroke-[2.5px] group-hover:animate-[wiggle_1s_ease-in-out_infinite] size-md bg-background-strong grid justify-center items-center">
							<RectangleEllipsis />
						</div>
					}
					title={"Recibir monedas digitales"}
					description={
						"El dinero se acreditara en monedas digitales. Esta se podrá retirar a cualquier billetera en la red de BSC. Para mas información visitar la documentación."
					}
					buttonText="Abrir documentación web"
					buttonLink="https://docs.talo.com.ar/withdrawals/introduction"
				/>
				{!isTiendanubeInstalled && !isWooCommerceInstalled ? (
					<PluginsSection subSections={subSections} />
				) : null}
				{isTiendanubeInstalled || isWooCommerceInstalled ? (
					<>
						<Section
							title="Integraciones activas"
							footer="Para agregar nueva integraciones. Probá desvinculando la activa."
						>
							{isTiendanubeInstalled && (
								<Option
									key={integrationsAvailable.tiendanube.title}
									title={integrationsAvailable.tiendanube.title}
									description="Activa"
									icon={integrationsAvailable.tiendanube.icon}
									leading="div"
									div={null}
									iconInside={false}
									className="bg-background description:text-text-success"
								/>
							)}
							{isWooCommerceInstalled && (
								<Option
									key={integrationsAvailable.woocommerce.title}
									title={integrationsAvailable.woocommerce.title}
									description="Activa"
									icon={integrationsAvailable.woocommerce.icon}
									leading="div"
									div={null}
									iconInside={false}
									className="bg-background description:text-text-success"
								/>
							)}
						</Section>
						<Button
							onClick={() => {
								redirectToEndOfOnboarding(navigate, user)
							}}
						>
							Finalizar
						</Button>
					</>
				) : null}
			</FeatureLayout>
		</CenteredBody>
	)
}

export default CryptoBody
