"use client"
import { useState } from "react"
import { useAuth } from "../../context/AuthContext"
import {
	ChargeType,
	ChargeTypeItem,
	PaymentOption,
	PaymentOptionItem,
} from "../../pages/charge/charge-wizard-types"
import LabelButton from "../ui/buttons/LabelButton"
import Col from "../ui/layouts/column"
import { Option } from "../ui/options/option"
import { Section } from "../ui/section"
import { SectionHeader } from "../ui/section-header"
import { Sheet, SheetContent, SheetTrigger } from "../ui/sheet"

const ChargeTypeStep = ({
	chargeTypes,
	onSelectType,
}: {
	chargeTypes: { [key in ChargeType]: ChargeTypeItem }
	onSelectType: (type: ChargeType, paymentOptions: PaymentOption) => void
}) => {
	const [isSheetOpen, setIsSheetOpen] = useState(false)
	const [selectedType, setSelectedType] = useState<ChargeType>(ChargeType.LINK)
	const { user } = useAuth()

	return (
		<Col>
			<SectionHeader
				title="Seleccioná el medio"
				subtitle="Podés cargar tu QR en caja o generar uno temporal a través de un link de pago."
			/>
			<div className=" flex-row flex gap-betweenButtons pt-contentToButton">
				<Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
					{Object.entries(chargeTypes).map(([key, value]) => (
						<SheetTrigger key={key} asChild>
							<LabelButton
								size="full"
								disabled={value.disabled}
								label={value.label}
								key={key}
								onClick={() => {
									setSelectedType(key as ChargeType)

									//Si el usuario tiene id de pollux entonces le damos la opcion de elegir transferencia o crypto
									if (
										user &&
										user.pollux_account_id &&
										user.pollux_account_id !== ""
									) {
										console.log("pollux_account_id", user.pollux_account_id)
										setIsSheetOpen(!isSheetOpen)
									} else {
										//si no tiene usuario de pollux entonces ponemos crypto por default y no mosrtamos el sheet
										onSelectType(key as ChargeType, PaymentOption.CRYPTO)
									}
								}}
								icon={value.icon}
								variant="outline"
								data-test={`payment-link-option-button-${value.label}`}
							/>
						</SheetTrigger>
					))}
					<SheetContent className=" content-center py-20">
						<Col className="gap-belowSectionHeader">
							<SectionHeader
								title="Moneda"
								subtitle="Seleccioná la moneda con la que quieras realizar el cobro"
							></SectionHeader>

							<Section>
								{Object.entries(PaymentOption)
									.filter(
										([key, value]) =>
											value !== PaymentOption.PIX || user.fiat_payments_enabled
									)
									.map(([key, value]) => (
										<Option
											leading="arrow"
											title={PaymentOptionItem[value].label}
											description={PaymentOptionItem[value].description}
											key={key}
											iconInside={false}
											icon={
												<img
													src={`/currencies/${PaymentOptionItem[
														value
													].icon.toLocaleLowerCase()}-icon.svg`}
													width={24}
													alt={key}
												/>
											}
											onClick={() =>
												onSelectType(selectedType, value as PaymentOption)
											}
										></Option>
									))}
							</Section>
						</Col>
					</SheetContent>
				</Sheet>
			</div>
		</Col>
	)
}

export default ChargeTypeStep
