import { useSearchParams } from "react-router-dom"
import TxTable from "../../../components/tables/transactions-table/table"
import CenteredTableBody from "../../../components/ui/layouts/platform/body/CenteredTableBody"
import { HoverBorderGradient } from "../../../components/ui/hover-border-gradient"
import { Ghost, Phone } from "lucide-react"
import { Button } from "../../../components/ui/button"
import Row from "../../../components/ui/layouts/row"

const TransactionsBody = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	return (
		<CenteredTableBody>
			<TxTable
				transaction_id={searchParams.get("transaction_id") ?? undefined}
				showFilters
				scrollable
				viewOptions
				onCloseModal={() => {
					setSearchParams({})
				}}
			/>
		</CenteredTableBody>
	)
}

export default TransactionsBody
