import { ArrowRight } from "lucide-react"
import { useMediaQuery } from "../../../utils/useMediaQuery"
import CenteredBody from "../../ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../../ui/section-header"
import Spacer from "../../ui/spacer"
import { Heading6 } from "../../ui/texts/TextStyle"

const ConfirmationPending = () => {
	return (
		<div>
			<Spacer axis="vertical" type="betweenComponents"></Spacer>
			<img
				src="/animations/pending-confirmation.gif"
				alt="PasosTutorial"
				className="max-w-2xl mt-1"
			/>

			<Spacer axis="vertical" value="h-16"></Spacer>
			<CenteredBody>
				<SectionHeader
					className="title:text-text subtitle:text-text-muted"
					title="Verificando tu transacción"
					subtitle="Esto puede tardar unos segundos. Por favor aguarda en esta pantalla,te notificaremos cuando hayamos procesado tu pago."
				></SectionHeader>
			</CenteredBody>
			<div className="flex items-center justify-center text-text mt-10">
				<Heading6>¿Necesitás ayuda?</Heading6>

				<ArrowRight className="text-icon size-icon mx-2" />

				{/* <GradientUnderlineButton
						href="https://wa.me/541153894775"
						dark={true}
					> */}
				<a href="https://wa.me/541153894775">
					<Heading6 className="underline">Contactate con soporte</Heading6>
				</a>
				{/* </GradientUnderlineButton> */}
			</div>
		</div>
	)
}

export default ConfirmationPending
