import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { SettingsItemRow } from "../../../components/app/settings/SettingsItem"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import { Section } from "../../../components/ui/section"
import { SectionHeader } from "../../../components/ui/section-header"
import { useAnalytics } from "../../../context/AnalyticsContext"
import { useAuth } from "../../../context/AuthContext"
import { useFlags } from "../../../context/FlagsContext"
import { updateUser as serviceUpdateUser } from "../../../services/users"
import { SettingsSectionItem } from "../items"

const PaymentMethodsBody = ({
	subSections,
}: {
	subSections: SettingsSectionItem[]
}) => {
	const { user, updateUser } = useAuth()
	const { getFlag } = useFlags()

	return (
		<CenteredBody>
			<SectionHeader
				title="Opciones para tu cliente en el checkout"
				subtitle="Aca podrás personalizar las opciones de pago estarán disponibles para tus clientes durante el proceso de compra."
			></SectionHeader>

			{subSections.map((section) => (
				<Section
					title={section.title}
					footer={section.description}
					key={section.title}
				>
					{section.subItems.map((item) => {
						if (item.featureFlag) {
							const flag = getFlag(item.featureFlag).asBoolean()
							if (flag === false) {
								return (
									<SettingsItemRow
										subleading={item.flaggedMethodState || item.subleading}
										description={item.description}
										key={item.title}
										title={item.flaggedTitle || item.title}
										icon={item.icon}
										iconInside={item.iconInside}
										disabled={true}
										link={""}
									/>
								)
							}
						}
						return (
							<SettingsItemRow
								subleading={item.subleading}
								description={item.description}
								link={item.link}
								key={item.title}
								title={item.title}
								icon={item.icon}
								iconInside={item.iconInside}
							/>
						)
					})}
				</Section>
			))}
		</CenteredBody>
	)
}

export default PaymentMethodsBody
