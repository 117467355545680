import { ColumnDef, Row } from "@tanstack/react-table"
import { TableTransaction } from "../columns"

export function OrderCell({
	accessorKey,
	header,
}: {
	accessorKey: string
	header: string
}): ColumnDef<TableTransaction> {
	return {
		accessorKey: accessorKey,
		size: 270,
		header: () => <div className="text-left">{header}</div>,
		cell: ({ row }: { row: Row<TableTransaction> }) => {
			const orderId = row.getValue(accessorKey) as string
			if (orderId === "-") {
				return (
					<div className="justify-end  h-4 w-full rounded-xs bg-background-elevated"></div>
				)
			} else {
				return (
					<div className="text-h6 w-fullblock overflow-hidden overflow-ellipsis m-0">
						#{orderId}
					</div>
				)
			}
		},
	}
}
