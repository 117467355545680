import { KeyRound, Puzzle, RectangleEllipsis } from "lucide-react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Alert } from "../../../components/ui/alert"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import { Option } from "../../../components/ui/options/option"
import { Section } from "../../../components/ui/section"
import { SectionHeader } from "../../../components/ui/section-header"
import { useAuth } from "../../../context/AuthContext"
import { StoreType } from "../../../services/users"
import { fiatCurrencies } from "../../../utils/currencies"
import { CryptoCover } from "./PaymentsCover"
import FeatureLayout from "../../../components/ui/layouts/platform/FeatureLayout"

export interface Plugin {
	title: string
	icon: React.ReactNode
}

const integrationsAvailable: { [key in StoreType]: Plugin } = {
	tiendanube: {
		title: "Tiendanube + Talo Pay",
		icon: <img src="/dashboard/plugins/tiendanube.svg" className="size-full" />,
	},
	woocommerce: {
		title: "Woocommerce + Talo Pay",
		icon: (
			<img src="/dashboard/plugins/woocommerce.svg" className="size-full" />
		),
	},
}

export default function Crypto() {
	const navigate = useNavigate()
	const { user, stores } = useAuth()
	const [isTiendanubeInstalled, setIsTiendanubeInstalled] = useState(false)
	const [isWooCommerceInstalled, setIsWooCommerceInstalled] = useState(false)

	useEffect(() => {
		if (stores) {
			if (process.env.REACT_APP_TIENDANUBE_APP_ID === undefined) {
				return
			}

			const isTiendanubeStore = stores.some(
				(store: any) =>
					store.store_type === StoreType.TIENDANUBE &&
					store.store_apps.find(
						(app: any) =>
							app.app_id === process.env.REACT_APP_TIENDANUBE_APP_ID ||
							app.app_id === process.env.REACT_APP_TIENDANUBE_HOMO_APP_ID
					)
			)

			if (isTiendanubeStore) {
				setIsTiendanubeInstalled(true)
			}

			const isWooCommerceStore = stores.some(
				(store: any) => store.store_type === StoreType.WOOCOMMERCE
			)

			if (isWooCommerceStore) {
				setIsWooCommerceInstalled(true)
			}
		}
	}, [stores])

	return (
		<FeatureLayout
			coverImage={<CryptoCover />}
			sectionHeaderProps={{
				title: "Pagos en criptomonedas",
				subtitle:
					"Aceptá criptomonedas desde cualquier billetera y expandite a nuevos clientes. Conectá tu cuenta de Talo a tu tienda online para agregar el botón de pago y comenzar a cobrar a clientes de todo el mundo.",
			}}
		>
			<Section>
				<Option
					title="Cotización dólar"
					description="Ajustá el tipo de cambio que más te sirva"
					icon={<img src={fiatCurrencies.USD.flagPath} alt="USD" />}
					iconInside={false}
					leading="arrow"
					onClick={() => navigate("/settings/payment-methods/pricing-profile")}
				/>
				<Alert
					variant="link"
					icon={
						<div className="rounded-full [&>*]:size-[18px] [&>*]:stroke-[2.5px] group-hover:animate-[wiggle_1s_ease-in-out_infinite] size-md bg-background-strong grid justify-center items-center">
							<RectangleEllipsis />
						</div>
					}
					title={"Recibir monedas digitales"}
					description={
						"El dinero se acreditará en criptomonedas. Podrás retirarla a cualquier billetera que soporte la red BSC o BNB Chain. Para más información visitar la documentación."
					}
					buttonText="Abrir documentación web"
					buttonLink="https://docs.talo.com.ar/withdrawals/introduction"
				/>
			</Section>

			{isTiendanubeInstalled || isWooCommerceInstalled ? (
				<Section
					title="Integraciones activas"
					footer="Para agregar nueva integraciones. Probá desvinculando la activa."
				>
					{isTiendanubeInstalled && (
						<Option
							key={integrationsAvailable.tiendanube.title}
							title={integrationsAvailable.tiendanube.title}
							description="Activa"
							icon={integrationsAvailable.tiendanube.icon}
							leading="div"
							div={null}
							iconInside={false}
							className="bg-background description:text-text-success"
						/>
					)}
					{isWooCommerceInstalled && (
						<Option
							key={integrationsAvailable.woocommerce.title}
							title={integrationsAvailable.woocommerce.title}
							description="Activa"
							icon={integrationsAvailable.woocommerce.icon}
							leading="div"
							div={null}
							iconInside={false}
							className="bg-background description:text-text-success"
						/>
					)}
					<Option
						title="Credenciales"
						icon={<KeyRound />}
						leading="arrow"
						className="description:text-text-success"
						onClick={() => navigate("/settings/plugins/credentials")}
					/>
				</Section>
			) : (
				<Section title="Conectá tu cuenta">
					<Option
						title="Integraciones"
						description="Conectá tu tienda online"
						icon={<Puzzle />}
						leading="arrow"
						onClick={() => navigate("plugins")}
					/>
				</Section>
			)}
		</FeatureLayout>
	)
}
